import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { BankAccount, Identity, Organization } from 'store/api/api.types';
import { CountryCode } from 'constants/countries';
import { useCurrentUser } from 'context/CurrentUserContext';
import Badge from 'components/core/Badge/Badge';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

import IdentityBankAccount from './IdentityBankAccount/IdentityBankAccount';
import styles from './IdentityBankAccounts.module.scss';

import ModalBankAccountCreate from '../../ModalBankAccountCreate/ModalBankAccountCreate';

const IdentityBanks: FC<{
  identity: Identity;
  organizationId: Organization['id'];
}> = ({ identity, organizationId }) => {
  const { isUserAllowedTo } = useCurrentUser();

  const canCreateBankAccount = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateBankAccount),
    [organizationId, isUserAllowedTo],
  );

  const banks = useMemo<BankAccount[]>(
    () =>
      [...(identity?.bankAccounts || [])]
        .sort((a, b) => {
          return `${a.country}-${a.bankName}-${a.accountNumber.slice(-4)}` <
            `${b.country}-${b.bankName}-${b.accountNumber.slice(-4)}`
            ? -1
            : 1;
        })
        .map(bank => ({
          attributes: {
            accountNumber: bank.accountNumber,
            accountType: bank.accountType,
            bankName: bank.bankName,
            bicSwiftCode: bank.bicSwiftCode || '',
            country: bank.country as CountryCode,
            currency: bank.assetType,
            id: bank.id,
            nameOnBankAccount: bank.nameOnBankAccount,
            routingCode: bank.routingCode,
          },
          id: bank.id,
          relationships: {
            accountHolders: { data: [] },
            identities: { data: [] },
          },
        })),
    [identity],
  );

  const identityName = useMemo(() => {
    if (identity.businessName && identity.businessName !== ' ') {
      return identity.businessName;
    }
    return `${identity.firstName} ${identity.lastName} `;
  }, [identity]);

  return (
    <Text className={styles.banks} variant='bodyCopySmall'>
      {banks.map(bank => (
        <IdentityBankAccount key={bank.id} bankAccount={bank} organizationId={organizationId} />
      ))}
      {canCreateBankAccount && (
        <Badge
          className={styles.badge}
          color='orange'
          onClick={() =>
            modalService.openModal(
              {
                title: `Add a Bank for ${identityName}`,
              },
              <ModalBankAccountCreate identity={identity} organizationId={organizationId} />,
            )
          }
        >
          <Icon iconName='circlePlus' marginRight={2} size={1.8} />
          <span>Add</span>
        </Badge>
      )}
    </Text>
  );
};

export default IdentityBanks;
