import { ComponentConfig } from './types';

export const enum IdentityDetailsComponentId {
  address = 'address',
  businessName = 'businessName',
  city = 'city',
  country = 'country',
  email = 'email',
  firstName = 'firstName',
  identificationNumber = 'identificationNumber',
  identificationType = 'identificationType',
  lastName = 'lastName',
  phone = 'phone',
  postalCode = 'postalCode',
  stateProvince = 'stateProvince',
}

export type IdentityDetailsComponents = Record<IdentityDetailsComponentId, ComponentConfig>;
