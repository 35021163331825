import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import Text from 'components/core/Text/Text';

import { NoResultsMessageProps } from './NoResultsMessage.types';
import styles from './NoResultsMessage.module.scss';

const NoResultsMessage: FC<NoResultsMessageProps> = ({
  header,
  message,
  button,
  className,
  ...props
}) => {
  return (
    <Box className={cx(styles.root, className)} {...props}>
      <Text align='center' variant='sectionHeaderLarge'>
        {header}
      </Text>
      {message && (
        <Text align='center' marginTop={3} variant='subCopyStandard'>
          {message}
        </Text>
      )}
      {button && (
        <Button align='center' display='inline-block' marginTop={6} variant='primary' {...button} />
      )}
    </Box>
  );
};

export default NoResultsMessage;
