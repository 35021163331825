import { Dispatch, SetStateAction, createContext } from 'react';

import { getContextFromProvider } from './context.utils';

export interface MobileMenuContextType {
  isMenuOpen: boolean;
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
}

const MobileMenuContext = createContext<MobileMenuContextType | undefined>({
  isMenuOpen: false,
  setIsMenuOpen: () => {},
});

export const useMobileMenu = getContextFromProvider<MobileMenuContextType>(MobileMenuContext);

export default MobileMenuContext;
