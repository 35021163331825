import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { SelectFieldSingleValueProps } from './SelectFieldSingleValue.types';
import styles from './SelectFieldSingleValue.module.scss';

const SelectFieldSingleValue: FC<SelectFieldSingleValueProps> = ({
  data,
  showSublabelForSelected,
  innerProps,
  isDisabled,
  hasError,
}) => {
  const displayLabel = useMemo(() => {
    const { label, sublabel, component, value } = data;
    if (component) {
      return component;
    }

    let optionLabel = label;
    if (sublabel && showSublabelForSelected) {
      optionLabel += ` - ${sublabel}`;
    }
    return value ? optionLabel : '';
  }, [data, showSublabelForSelected]);

  return (
    <div
      className={cx(styles.root, isDisabled && styles.isDisabled, hasError && styles.hasError)}
      {...innerProps}
    >
      {displayLabel}
    </div>
  );
};

export default SelectFieldSingleValue;
