import React, { FC } from 'react';

import Box from 'components/core/Box/Box';

import { TooltipProps } from './Tooltip.types';

const Tooltip: FC<TooltipProps> = ({ children, label, isHidden = false, ...props }) => {
  return isHidden ? (
    <Box {...props}>{children}</Box>
  ) : (
    <Box data-rh={label} {...props}>
      {children}
    </Box>
  );
};

export default Tooltip;
