import React, { FC } from 'react';
import cx from 'classnames';

import { EmptyFieldProps } from './EmptyField.types';
import styles from './EmptyField.module.scss';

const EmptyField: FC<EmptyFieldProps> = ({ children, className, label }) => (
  <div className={cx(styles.root, className)}>
    <div className={cx(styles.fieldWrapper)}>
      <fieldset className={styles.fieldset}>
        <legend className={styles.legend}>{label && <span>{label}</span>}</legend>
      </fieldset>
      <label className={styles.label}>{label}</label>
      {children}
    </div>
  </div>
);

export default EmptyField;
