import { useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { Organization } from 'store/api/api.types';
import { getOrganizationMenuItems } from 'router/menuItems';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import OrganizationSwitcherDropdown from 'components/dedicated/organization/OrganizationSwitcherDropdown/OrganizationSwitcherDropdown';

import styles from './OrganizationNavbar.module.scss';

const OrganizationNavbar = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: Organization['id'] };
  const { data: organization } = useGetOrganizationQuery({ organizationId });
  const { isUserAllowedTo } = useCurrentUser();
  const menuItems = useMemo(
    () => (organization ? getOrganizationMenuItems(organization, isUserAllowedTo) : []),
    [organization, isUserAllowedTo],
  );

  return (
    <div className={styles.root}>
      <div className={styles.navigationBar}>
        <nav className={styles.organizationMenu}>
          {menuItems.map((item, index) => (
            <Button
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isNavlink
              label={item.label}
              size='small'
              to={item.path.absolute}
              variant='navigation'
            />
          ))}
        </nav>
        <OrganizationSwitcherDropdown className={styles.organizationDropdown} />
      </div>
    </div>
  );
};

export default OrganizationNavbar;
