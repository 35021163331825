import React, { FC } from 'react';
import cx from 'classnames';

import { TransactionType } from 'store/api/api.types';
import { arrowRight } from 'components/core/Svg/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';

import { TransactionDescriptionProps } from './TransactionDescription.types';
import styles from './TransactionDescription.module.scss';

const TransactionDescription: FC<TransactionDescriptionProps> = ({
  className,
  sourceAsset,
  targetAsset,
  transactionType,
  ...props
}) => (
  <Box className={cx(styles.root, className)} {...props}>
    {transactionType === TransactionType.Deposit && 'Deposit'}
    {transactionType === TransactionType.Withdrawal && 'Withdrawal'}
    {![TransactionType.Deposit, TransactionType.Withdrawal].includes(transactionType) && (
      <>
        {sourceAsset}
        <Svg className={styles.arrowIcon} img={arrowRight} size={1.2} />
        {targetAsset}
      </>
    )}
  </Box>
);

export default TransactionDescription;
