import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { Invite, Organization } from 'store/api/api.types';
import { showError, showSuccess } from 'services/notificationService';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useDeleteInviteMutation, useResendInviteMutation } from 'store/api/rootApi';
import Dropdown from 'components/core/Dropdown/Dropdown';
import Icon from 'components/core/Icon/Icon';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Text from 'components/core/Text/Text';

import styles from './InviteActionsButton.module.scss';

const InviteActionsButton: FC<{ invite: Invite; organizationId: Organization['id'] }> = ({
  invite,
  organizationId,
}) => {
  const [deleteInvite, { isLoading: isDeleting }] = useDeleteInviteMutation();
  const [resendInvite, { isLoading: isResending }] = useResendInviteMutation();
  const { isUserAllowedTo } = useCurrentUser();

  const canResendInvite = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.ResendInvite),
    [organizationId, isUserAllowedTo],
  );
  const canDeleteInvite = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.DeleteInvite),
    [organizationId, isUserAllowedTo],
  );

  const handleDeleteInvite = async () => {
    const deleteInviteResult = await deleteInvite({ inviteId: invite.id, organizationId });
    if (!('error' in deleteInviteResult)) {
      showSuccess(
        <Text variant='bodyCopySmall'>
          You have successfully deleted the invite for <strong>{invite.email}</strong>.
        </Text>,
      );
    } else {
      showError('Failed to delete invite - please try again later.');
    }
  };

  const handleResendInvite = async () => {
    const resendInviteResult = await resendInvite({ inviteId: invite.id, organizationId });
    if (!('error' in resendInviteResult)) {
      showSuccess(
        <Text variant='bodyCopySmall'>
          You have successfully resent the invite for <strong>{invite.email}</strong>.
        </Text>,
      );
    } else {
      showError('Failed to resend invite - please try again later.');
    }
  };

  const showMenu = canResendInvite || canDeleteInvite;

  const isProcessing = isDeleting || isResending;

  return showMenu ? (
    <Dropdown
      className={styles.alignRight}
      isDisabled={isProcessing}
      isLoading={isProcessing}
      LeadingIcon={<Icon iconName='more' size={2.4} />}
      variant='icon'
    >
      {canResendInvite && <MenuItem label='Resend invite' onClick={handleResendInvite} />}
      {canDeleteInvite && <MenuItem label='Delete invite' onClick={handleDeleteInvite} />}
    </Dropdown>
  ) : null;
};

export default InviteActionsButton;
