import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import React, { FC, useCallback, useMemo } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { BankAccount } from 'store/api/api.types';
import { ORGANIZATION_ROUTES } from 'router/constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { getBankNameWithAccountNumberPart } from 'helpers/transaction.helpers';
import { getUriId, isFiat } from 'utils/format';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Text from 'components/core/Text/Text';

const NO_BANKS = 'NO-BANKS';

const BankSelect: FC<{
  assetType?: AssetType;
  bankAccounts: BankAccount[];
  bankId: string;
  bankType: 'sender' | 'recipient';
  error?: string;
  isDisabled?: boolean;
  onSelect: (bank) => void;
}> = ({ assetType, bankAccounts, bankId, bankType, error, isDisabled, onSelect }) => {
  const navigate = useNavigate();

  const bankOptions = useMemo<SelectFieldOption[]>(() => {
    const banks = bankAccounts!
      .filter(bankAccount => bankAccount.attributes.currency === getUriId(assetType))
      .map(bankAccount => ({
        data: { currency: `asset:fiat:${bankAccount.attributes.currency}` },
        label: bankAccount.attributes.nameOnBankAccount,
        sublabel: getBankNameWithAccountNumberPart(bankAccount.attributes),
        value: bankAccount.id,
      }));

    if (banks.length === 0) {
      return [
        {
          component: (
            <Text align='center' variant='bodyCopySmall'>
              You haven&apos;t added a {bankType} with a bank account for this
              <br />
              currency yet or it is currently being reviewed. Please create
              <br />
              one{' '}
              <Text display='inline-block' variant='bodyLinkSmall'>
                here
              </Text>{' '}
              to continue.
            </Text>
          ),
          label: '',
          value: NO_BANKS,
        },
      ];
    }

    return banks;
  }, [assetType, bankAccounts, bankType]);

  const selectFieldLabel = useMemo(() => {
    return `${getUriId(assetType)} ${capitalize(bankType)}`;
  }, [assetType, bankType]);

  const selectBank = useCallback(
    (bank: SelectFieldOption | null) => {
      if (bank?.value === NO_BANKS) {
        navigate(`../${ORGANIZATION_ROUTES.SENDERS_RECIPIENTS.relative}`);
        return;
      }
      onSelect(bank);
    },
    [navigate, onSelect],
  );

  if (!isFiat(assetType)) {
    return null;
  }

  return (
    <SelectField
      error={error}
      isDisabled={isDisabled}
      label={selectFieldLabel}
      onSelect={selectBank}
      options={bankOptions}
      showSublabelForSelected
      value={bankOptions.find(option => option.value === bankId)}
    />
  );
};

export default BankSelect;
