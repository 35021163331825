import { TransactionStatus, TransactionType } from 'store/api/api.types';

// the real pending statuses - meaning they are not final
export const TRANSACTION_PENDING_STATUSES = [
  TransactionStatus.Created,
  TransactionStatus.AwaitingComplianceReview,
  TransactionStatus.AwaitingFunds,
  TransactionStatus.ComplianceApproved,
  TransactionStatus.FundsReceived,
  TransactionStatus.Created,
  TransactionStatus.InComplianceReview,
  TransactionStatus.ProcessingPayment,
  TransactionStatus.ProcessingSettlement,
  TransactionStatus.ProcessingWithdrawal,
  TransactionStatus.PaymentProcessed,
  TransactionStatus.SettlementProcessed,
  TransactionStatus.WithdrawalProcessed,
];

// the "formal" completion - last status that will be shown in the transaction details
export const TRANSACTION_COMPLETED_STATUSES = [
  TransactionStatus.Cancelled,
  TransactionStatus.Completed,
  TransactionStatus.ComplianceRejected,
];

// statuses used to filter transactions that are considered completed
export const TRANSACTION_COMPLETED_FILTER_STATUSES = [
  ...TRANSACTION_COMPLETED_STATUSES,
  TransactionStatus.PaymentProcessed,
  TransactionStatus.SettlementProcessed,
  TransactionStatus.WithdrawalProcessed,
];

// statuses used to filter transactions that are considered pending
export const TRANSACTION_PENDING_FILTER_STATUSES = TRANSACTION_PENDING_STATUSES.filter(
  status => !TRANSACTION_COMPLETED_FILTER_STATUSES.includes(status),
);

export const TRANSACTION_TYPE_LABELS: Record<TransactionType, string> = {
  [TransactionType.Deposit]: 'Deposit',
  [TransactionType.Fx]: 'Exchange',
  [TransactionType.Offramp]: 'Withdrawal',
  [TransactionType.Onramp]: 'Deposit',
  [TransactionType.Withdrawal]: 'Withdrawal',
};

export const TRANSACTION_STATUS_LABELS: Record<TransactionStatus, string> = {
  [TransactionStatus.Created]: 'Created',
  [TransactionStatus.AwaitingFunds]: 'Awaiting Funds',
  [TransactionStatus.AwaitingComplianceReview]: 'Awaiting Compliance Review',
  [TransactionStatus.FundsReceived]: 'Funds Received',
  [TransactionStatus.InComplianceReview]: 'In Compliance Review',
  [TransactionStatus.ComplianceApproved]: 'Compliance Approved',
  [TransactionStatus.ProcessingSettlement]: 'Processing Settlement',
  [TransactionStatus.ProcessingPayment]: 'Processing Payment',
  [TransactionStatus.ProcessingWithdrawal]: 'Processing Withdrawal',
  [TransactionStatus.SettlementProcessed]: 'Settlement Processed',
  [TransactionStatus.PaymentProcessed]: 'Payment Processed',
  [TransactionStatus.WithdrawalProcessed]: 'Withdrawal Processed',
  [TransactionStatus.Completed]: 'Completed',
  [TransactionStatus.ComplianceRejected]: 'Compliance Rejected',
  [TransactionStatus.Cancelled]: 'Cancelled',
};
