import React, { FC } from 'react';
import cx from 'classnames';

import { BadgeProps } from './Badge.types';
import styles from './Badge.module.scss';

const Badge: FC<BadgeProps> = ({ children, className, dataTestId, label, color, onClick }) => (
  <div
    className={cx(styles.root, styles[`color--${color}`], className)}
    data-testid={dataTestId}
    onClick={onClick}
  >
    {label}
    {children}
  </div>
);

export default Badge;
