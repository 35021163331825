import React, { FC, useMemo, useState } from 'react';

import { LocalFormError, Organization } from 'store/api/api.types';
import { SYSTEM_ROLE_DESCRIPTIONS } from 'constants/constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import {
  getFieldResponseErrorMessage,
  getLocalFormErrorMessage,
  isGenericErrorType,
} from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { uppercaseFirstChar } from 'utils/uppercaseFirstChar';
import {
  useCreateInviteMutation,
  useGetOrganizationQuery,
  useGetOrganizationRolesQuery,
} from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import InputField from 'components/core/Form/InputField/InputField';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalInviteMemberTitle = 'Invite Member';

const ModalInviteMember: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [inviteeRole, setInviteeRole] = useState<string>('');
  const { data: organization } = useGetOrganizationQuery({ organizationId });
  const { data: organizationRoles, isFetching: isFetchingRoles } = useGetOrganizationRolesQuery({
    organizationId,
  });

  const [clientError, setClientError] = useState<undefined | LocalFormError>(undefined);
  const [createInvite, { isLoading: isCreatingInvite, error: responseError }] =
    useCreateInviteMutation();

  const roleOptions: SelectFieldOption[] = useMemo(
    () =>
      (organizationRoles || []).map(role => ({
        label: uppercaseFirstChar(role.name),
        sublabel: SYSTEM_ROLE_DESCRIPTIONS[role.name],
        value: role.id,
      })),
    [organizationRoles],
  );

  const selectedOption = useMemo(
    () => roleOptions.find(role => role.value === inviteeRole),
    [roleOptions, inviteeRole],
  );

  const handleCreateInvite = async e => {
    e.preventDefault();
    if (!inviteeRole) {
      return setClientError({ fieldName: 'roleId', message: 'Please select a role.' });
    }
    const result = await createInvite({ email: emailAddress, organizationId, roleId: inviteeRole });
    if (!('error' in result)) {
      modalService.closeCurrentModal();
      showSuccess(
        <Text variant='bodyCopySmall'>
          You have successfully invited <strong>{emailAddress}</strong>.
        </Text>,
      );
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to invite member - please try again later.');
    }
  };

  return (
    <form onSubmit={handleCreateInvite}>
      {isFetchingRoles ? (
        <Spinner />
      ) : (
        <>
          <ModalContent variant='noBottomPadding'>
            <Text>
              Enter the details for the person you want to invite to{' '}
              <Text Tag='span' variant='legacyBodyEmphasized'>
                {organization?.name}
              </Text>
              .
            </Text>
            <InputField
              error={getFieldResponseErrorMessage('email', responseError)}
              isDisabled={isCreatingInvite}
              isRequired
              label='Email Address'
              name='email'
              onChange={({ target: { value } }) => setEmailAddress(value)}
              placeholder='Enter email address'
              type='email'
              value={emailAddress}
            />
            <SelectField
              error={
                getLocalFormErrorMessage('roleId', clientError) ||
                getFieldResponseErrorMessage('roleId', responseError)
              }
              isDisabled={isCreatingInvite}
              isRequired
              label='Role'
              name='roleId'
              onChange={({ target: { value } }) => {
                setClientError(undefined);
                setInviteeRole(value);
              }}
              options={roleOptions}
              placeholder='Select role'
              value={selectedOption}
            />
          </ModalContent>
          <ModalContent variant='footerButtonsWithoutTopBorder'>
            <Button
              isDisabled={isCreatingInvite}
              label='Cancel'
              onClick={() => {
                modalService.closeCurrentModal();
              }}
              variant='textCta'
            />
            <Button isLoading={isCreatingInvite} label='Invite' type='submit' variant='primary' />
          </ModalContent>
        </>
      )}
    </form>
  );
};

export default ModalInviteMember;
