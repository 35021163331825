import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import generateUid from 'utils/generateUid';

import SpinnerProps from './Spinner.types';
import styles from './Spinner.module.scss';

const Spinner: FC<SpinnerProps> = ({
  color = 'default',
  display = 'flex',
  size = 'medium',
  variant = 'default',
  ...props
}) => {
  const { spinnerSize, strokeWidth } = useMemo(
    () =>
      ({
        large: {
          spinnerSize: 48,
          strokeWidth: 4,
        },
        medium: {
          spinnerSize: 32,
          strokeWidth: 3,
        },
        small: {
          spinnerSize: 18,
          strokeWidth: 3,
        },
        xlarge: {
          spinnerSize: 64,
          strokeWidth: 5,
        },
        xsmall: {
          spinnerSize: 12,
          strokeWidth: 2,
        },
      })[size],
    [size],
  );

  const startPointX = strokeWidth / 2;
  const startPointY = spinnerSize / 2;
  const endPointX = spinnerSize / 2;
  const endPointY = spinnerSize - startPointX;
  const ellipseRadius = (spinnerSize - strokeWidth) / 2;

  const spinnerId = useMemo(() => generateUid(), []);

  return (
    <Box display={display} {...props}>
      <div
        className={styles.spinner}
        style={{
          height: spinnerSize,
          width: spinnerSize,
        }}
      >
        <svg viewBox={`0 0 ${spinnerSize} ${spinnerSize}`}>
          <defs>
            <mask id={spinnerId}>
              <rect fill='black' height={spinnerSize} width={spinnerSize} />
              {variant === 'default' && (
                <path
                  d={`M ${startPointX},${startPointY} A ${ellipseRadius} ${ellipseRadius} 0 1 1 ${endPointX},${endPointY}`}
                  fill='none'
                  stroke='white'
                  strokeLinecap='round'
                  strokeWidth={strokeWidth}
                />
              )}
              {variant === 'button' && (
                <circle
                  cx={spinnerSize / 2}
                  cy={spinnerSize / 2}
                  fill='none'
                  r={spinnerSize / 2 - strokeWidth / 2}
                  stroke='white'
                  strokeWidth={strokeWidth}
                />
              )}
            </mask>
          </defs>
          <foreignObject height={spinnerSize} mask={`url(#${spinnerId})`} width={spinnerSize}>
            <div className={cx(styles.colorBoard, styles[`color--${color}`])} />
          </foreignObject>
        </svg>
      </div>
    </Box>
  );
};

export default Spinner;
