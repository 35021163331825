import React, { FC } from 'react';
import cx from 'classnames';

import { DefaultComponentWithChildren } from 'components/components.types';

import styles from './FrameBox.module.scss';

const FrameBox: FC<DefaultComponentWithChildren> = ({ children, className, dataTestId }) => (
  <div className={cx(styles.outerContainer, className)} data-testid={dataTestId}>
    <div className={cx(styles.innerContainer, className)}>{children}</div>
  </div>
);

export default FrameBox;
