import { Outlet } from 'react-router-dom';
import React, { FC } from 'react';

import Topbar from 'components/dedicated/Topbar/Topbar';

import AppLayoutProps from './AppLayout.types';
import styles from './AppLayout.module.scss';

const AppLayout: FC<AppLayoutProps> = ({
  showOrganizationSwitcher = true,
  showUserMenu = true,
}) => (
  <div className={styles.root}>
    <Topbar showOrganizationSwitcher={showOrganizationSwitcher} showUserMenu={showUserMenu} />
    <main className={styles.contentCard}>
      <Outlet />
    </main>
  </div>
);

export default AppLayout;
