import { ErrorCode } from 'react-dropzone';

/* eslint-disable @typescript-eslint/naming-convention */
export const acceptedFileTypes = {
  DOCX: { 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'] },
  JPG: { 'image/jpeg': ['.jpg', '.jpeg'] },
  PDF: { 'application/pdf': ['.pdf'] },
  PNG: { 'image/png': ['.png'] },
  XLSX: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
};

export const defaultOnDropRejectedErrors = {
  [ErrorCode.FileInvalidType]: 'This file type not supported.',
  [ErrorCode.FileTooLarge]: 'File is too large.',
  [ErrorCode.FileTooSmall]: 'File is too small.',
  [ErrorCode.TooManyFiles]: 'Exceeded number of files allowed.',
};
