import { ValueContainerProps, components } from 'react-select';
import React, { FC } from 'react';

import styles from './SelectFieldValueContainer.module.scss';

import { SelectFieldOption } from '../SelectField.types';

const SelectFieldValueContainer: FC<ValueContainerProps<SelectFieldOption>> = ({
  children,
  ...props
}) => {
  return (
    <components.ValueContainer className={styles.root} {...props}>
      {children}
    </components.ValueContainer>
  );
};

export default SelectFieldValueContainer;
