import { AssetType, AssetTypePairRule } from 'constants/assetTypes.types';
import { DefaultComponentWithChildren } from 'components/components.types';

export enum AssetTypeBalanceValidity {
  Empty = 'empty',
  LessThanMin = 'less_than_min',
  MoreThanMax = 'more_than_max',
  Valid = 'valid',
}

export interface AmountValidationResult {
  errorMessage: string;
  status: AssetTypeBalanceValidity;
}

export type AmountValidationFunction = (
  amount: string,
  assetType: AssetType,
  assetTypePairRule?: AssetTypePairRule,
) => AmountValidationResult;

export interface AssetTypeFieldProps extends DefaultComponentWithChildren {
  amountFieldValue?: string;
  amountLabel: string;
  amountValidatorFunction?: AmountValidationFunction;
  assetType?: AssetType;
  assetTypePair?: AssetType;
  assetTypes?: AssetType[];
  error?: string;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  onAmountChange?: (amount: string) => void;
  onAssetTypeChange?: (assetType: AssetType) => void;
  onValidationChange?: (status: AssetTypeBalanceValidity) => void;
  readOnlyTooltipMessage?: string;
}
