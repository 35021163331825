import React, { FC } from 'react';

import { InputFieldProps } from 'components/core/Form/InputField/InputField.types';
import Icon from 'components/core/Icon/Icon';
import Tooltip from 'components/core/Tooltip/Tooltip';

import TextInput from './TextInput';
import styles from './RoutingCodeInput.module.scss';

const RoutingCodeInput: FC<InputFieldProps & { tooltipLabel: string }> = ({
  tooltipLabel,
  ...props
}) => (
  <TextInput {...props}>
    <Tooltip
      className={styles.routingCodeHelp}
      label={
        tooltipLabel ||
        'A 9-digit code used to identify US banks. This can usually be found on the bottom left of checks or ask the bank for it.'
      }
    >
      <Icon iconName='circleQuestion' size={1.8} />
    </Tooltip>
  </TextInput>
);

export default RoutingCodeInput;
