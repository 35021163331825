export interface InsertSnippetParams {
  attrs?: { [attr: string]: any };
  onAbort?: (() => void) | null;
  onError?: (() => void) | null;
  onLoad?: (() => void) | null;
  snippet?: string;
  tagType: string;
  targetNode: HTMLElement;
}

/**
 * Injects code snippet into the document.
 *
 * @param tagType what tag should be created as snippet's container
 * @param snippet snippet's code
 * @param targetNode node to inject the snippet into
 * @param attrs object with new node's attributes defined as standard key-value pairs
 * @param onLoad callback to be executed on element load event
 * @param onError callback to be executed on element load error event
 * @param onAbort callback to be executed on element load abort event
 */
export default function insertSnippet({
  tagType,
  targetNode,
  snippet = '',
  attrs = {},
  onLoad = null,
  onError = null,
  onAbort = null,
}: InsertSnippetParams): void {
  const newElement = document.createElement(tagType);
  newElement.innerHTML = snippet;

  if (onLoad) {
    newElement.addEventListener('load', onLoad, false);
  }

  if (onError) {
    newElement.addEventListener('error', onError, false);
  }

  if (onAbort) {
    newElement.addEventListener('abort', onAbort, false);
  }

  Object.keys(attrs).forEach(key => {
    newElement.setAttribute(key, attrs[key]);
  });

  targetNode.insertBefore(newElement, targetNode.childNodes[0]);
}
