import { ComponentConfig } from './types';

export const enum BankDetailsComponentId {
  accountNumber = 'accountNumber',
  accountType = 'accountType',
  bankName = 'bankName',
  bicSwiftCode = 'bicSwiftCode',
  country = 'country',
  currency = 'currency',
  nameOnBankAccount = 'nameOnBankAccount',
  routingCode = 'routingCode',
}

export enum BankAccountType {
  CHECKING = 'checking',
  SAVINGS = 'savings',
}

export type BankDetailsComponents = Record<BankDetailsComponentId, ComponentConfig>;
