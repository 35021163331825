/**
 * Helper function that traverses the DOM upwards from a given element until it finds a scrollable container or reaches the body.
 */
export const getScrollableParent = (node: Node | null): HTMLElement | null => {
  let currentNode = node;
  while (currentNode) {
    if (currentNode === document.body) {
      return null;
    }
    const el = currentNode as HTMLElement;
    const { overflowX, overflowY } = window.getComputedStyle(el);
    const isScrollable =
      overflowY === 'auto' ||
      overflowY === 'scroll' ||
      overflowX === 'auto' ||
      overflowX === 'scroll';

    if (isScrollable) {
      return el;
    }
    currentNode = currentNode.parentNode;
  }
  return null;
};
