import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { Identity, Organization } from 'store/api/api.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Dropdown from 'components/core/Dropdown/Dropdown';
import Icon from 'components/core/Icon/Icon';
import MenuItem from 'components/core/MenuItem/MenuItem';
import modalService from 'services/modalService';

import ModalBankAccountCreate from '../../ModalBankAccountCreate/ModalBankAccountCreate';
import ModalIdentityDelete from '../../ModalIdentityDelete/ModalIdentityDelete';
import ModalIdentityDetails from '../../ModalIdentityDetails/ModalIdentityDetails';

const IdentityDropdown: FC<{
  identity: Identity;
  organizationId: Organization['id'];
}> = ({ identity, organizationId }) => {
  const { isUserAllowedTo } = useCurrentUser();
  const canCreateBankAccount = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateBankAccount),
    [organizationId, isUserAllowedTo],
  );

  const canDeleteIdentity = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.DeleteIdentity),
    [organizationId, isUserAllowedTo],
  );

  const identityName = useMemo(() => {
    if (identity.businessName && identity.businessName !== ' ') {
      return identity.businessName;
    }
    return `${identity.firstName} ${identity.lastName} `;
  }, [identity]);

  return (
    <Dropdown LeadingIcon={<Icon iconName='more' size={1.8} />} showArrow variant='icon'>
      <MenuItem
        label='View Details'
        onClick={() =>
          modalService.openModal(
            {
              title: 'Sender/Recipient Details',
            },
            <ModalIdentityDetails identity={identity} organizationId={organizationId} />,
          )
        }
      />
      {canCreateBankAccount && (
        <MenuItem
          label='Add Bank'
          onClick={() =>
            modalService.openModal(
              {
                title: `Add a Bank for ${identityName}`,
              },
              <ModalBankAccountCreate identity={identity} organizationId={organizationId} />,
            )
          }
        />
      )}
      {canDeleteIdentity && (
        <MenuItem
          label='Delete'
          onClick={() =>
            modalService.openModal(
              {
                title: 'Delete?',
              },
              <ModalIdentityDelete identity={identity} organizationId={organizationId} />,
            )
          }
        />
      )}
    </Dropdown>
  );
};

export default IdentityDropdown;
