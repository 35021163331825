import { useLocation } from 'react-router-dom';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';

import MobileMenuContext, { MobileMenuContextType } from './MobileMenuContext';

export const MobileMenuContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('blockScroll');
    } else {
      document.body.classList.remove('blockScroll');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <MobileMenuContext.Provider
      value={useMemo(
        (): MobileMenuContextType => ({ isMenuOpen, setIsMenuOpen }),
        [isMenuOpen, setIsMenuOpen],
      )}
    >
      {children}
    </MobileMenuContext.Provider>
  );
};
