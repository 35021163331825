/* eslint-disable react/no-unstable-nested-components */
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';

import { Invite, Organization, PaginationMode } from 'store/api/api.types';
import { uppercaseFirstChar } from 'utils/uppercaseFirstChar';
import { useGetInvitesQuery, useGetOrganizationRolesQuery } from 'store/api/rootApi';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';
import usePaginationLinks from 'hooks/usePaginationLinks';
import usePaginationQuery from 'hooks/usePaginationQuery';

import InviteActionsButton from './InviteActionsButton/InviteActionsButton';
import InviteStatusDisplay from './InviteStatusDisplay/InviteStatusDisplay';

const columnHelper = createColumnHelper<Invite>();

const InvitesTable: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const { pageBefore, pageAfter } = usePaginationQuery();
  const { data: invites, isLoading: isLoadingInvites } = useGetInvitesQuery({
    organizationId,
    page: {
      after: pageAfter || undefined,
      before: pageBefore || undefined,
      mode: PaginationMode.Cursor,
    },
  });
  const { data: organizationRoles, isLoading: isFetchingRoles } = useGetOrganizationRolesQuery({
    organizationId,
  });
  const { handleOnNextPage, handleOnPreviousPage } = usePaginationLinks(
    PaginationMode.Cursor,
    invites?.links,
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        cell: info => <Text variant='legacyBodySmallEmphasized'>{info.getValue()}</Text>,
        header: 'Email',
      }),
      columnHelper.accessor('roleId', {
        cell: info => {
          const roleId = info.getValue();
          const roleObject = organizationRoles?.find(role => role.id === roleId);
          return roleObject ? uppercaseFirstChar(roleObject.name) : 'Custom';
        },
        header: 'Role',
        id: 'role',
      }),
      columnHelper.accessor('expiresAt', {
        cell: props => <InviteStatusDisplay invite={props.row.original} />,
        header: 'Expiration',
      }),
      columnHelper.display({
        cell: props =>
          !props.row.original.isAccepted ? (
            <InviteActionsButton invite={props.row.original} organizationId={organizationId} />
          ) : null,
        id: 'actions',
      }),
    ],
    [organizationId, organizationRoles],
  );

  if (isLoadingInvites || isFetchingRoles) {
    return <Spinner />;
  }

  if ((invites?.data || []).length === 0) {
    return (
      <Text align='center' marginTop={5} variant='bodyCopySmall'>
        No Members Invited
      </Text>
    );
  }

  return (
    <Table
      handleOnNextPage={handleOnNextPage}
      handleOnPreviousPage={handleOnPreviousPage}
      options={{
        columns,
        data: invites?.data || [],
        getCoreRowModel: getCoreRowModel(),
      }}
    />
  );
};

export default InvitesTable;
