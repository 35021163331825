import React, { FC } from 'react';
import cx from 'classnames';

import { HexIdProps } from './HexId.types';
import styles from './HexId.module.scss';

const HexId: FC<HexIdProps> = ({
  className,
  dataTestId,
  id,
  minLeftChars = 8,
  minRightChars = 8,
}) => {
  const leftChars = id.slice(0, minLeftChars);
  const middleChars = id.slice(minLeftChars, -minRightChars);
  const rightChars = id.slice(-minRightChars);
  return (
    <span className={cx(styles.root, className)} data-testid={dataTestId}>
      <span className={styles.left}>{leftChars}</span>
      <span className={styles.middle}>{middleChars}</span>
      <span className={styles.right}>{rightChars}</span>
    </span>
  );
};

export default HexId;
