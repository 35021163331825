import { useParams } from 'react-router-dom';
import React, { FC } from 'react';

import { APP_ROUTES, ORGANIZATIONS_ROUTES } from 'router/constants';
import { DefaultComponent } from 'components/components.types';
import { useGetOrganizationsQuery } from 'store/api/rootApi';
import Divider from 'components/core/Divider/Divider';
import Dropdown from 'components/core/Dropdown/Dropdown';
import MenuItem from 'components/core/MenuItem/MenuItem';

const OrganizationSwitcherDropdown: FC<DefaultComponent> = ({ className, dataTestId }) => {
  const { organizationId } = useParams() as { organizationId: string };
  const { data: organizations, isFetching } = useGetOrganizationsQuery();

  let dropdownLabel = 'Select organization';
  if (organizations && organizations.length > 0) {
    organizations.forEach(({ id, name }) => {
      if (organizationId === id) {
        dropdownLabel = name;
      }
    });
  }

  return (
    <Dropdown
      className={className}
      dataTestId={dataTestId}
      isLoading={isFetching}
      label={dropdownLabel}
      menuPosition='left'
      menuWidth='wide'
      variant='dropdownOpaque'
    >
      {organizations &&
        organizations.map(({ id, name }) => (
          <MenuItem key={id} label={name} to={`${APP_ROUTES.ORGANIZATIONS.absolute}/${id}`} />
        ))}
      {organizations && organizations.length > 0 && <Divider />}
      <MenuItem
        label='Create Organization'
        to={ORGANIZATIONS_ROUTES.CREATE_ORGANIZATION.absolute}
        variant='dropdownCta'
      />
    </Dropdown>
  );
};

export default OrganizationSwitcherDropdown;
