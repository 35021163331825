import React, { FC, useMemo } from 'react';

import { BankAccount, Organization } from 'store/api/api.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useDeleteBankAccountMutation } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

import { getBankTitle } from '../config/bankDetails.helpers';

const ModalBankAccountDelete: FC<{
  bankAccount: BankAccount;
  organizationId: Organization['id'];
}> = ({ bankAccount, organizationId }) => {
  const [BankAccountDelete, { isLoading: isDeleting }] = useDeleteBankAccountMutation();

  const bankTitle = useMemo(() => getBankTitle(bankAccount.attributes), [bankAccount]);

  const handleDelete = async e => {
    e.preventDefault();

    const result = await BankAccountDelete({ bankAccountId: bankAccount.id, organizationId });
    if (!('error' in result)) {
      showSuccess(`You have successfully deleted ${bankTitle}.`);
      modalService.closeCurrentModal();
    } else if (isGenericErrorType(result.error)) {
      showError(
        `${bankTitle} was unable to be successfully deleted. Please try again. If the issue continues, please contact support.`,
      );
    }
    modalService.closeCurrentModal();
  };
  return (
    <>
      <ModalContent variant='noBottomPadding'>
        <Text marginBottom={4}>
          Are you sure you want to delete <b>{bankTitle}</b>?
        </Text>
        <Text marginBottom={4}>This bank will be unavailable for transactions.</Text>
      </ModalContent>
      <ModalContent variant='footerButtons'>
        <Button label='Cancel' onClick={() => modalService.closeCurrentModal()} variant='textCta' />
        <Button
          isLoading={isDeleting}
          label='Delete'
          onClick={handleDelete}
          variant='destructive'
        />
      </ModalContent>
    </>
  );
};

export default ModalBankAccountDelete;
