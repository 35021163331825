import React, { FC, memo } from 'react';

import { InputFieldProps } from 'components/core/Form/InputField/InputField.types';
import InputField from 'components/core/Form/InputField/InputField';

const TextInput: FC<InputFieldProps> = ({ id, ...props }) => (
  <InputField key={id} isRequired placeholder={`Enter ${props.label}`} {...props} />
);

export default memo(TextInput);
