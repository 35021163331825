import { useMemo } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { isFiat } from 'utils/format';

type UseAssetsSortedByTypeResponse = {
  showcaseCurrencies: AssetType[];
  supportedFiatCurrencies: AssetType[];
  supportedStablecoins: AssetType[];
};

export const useAssetsSortedByType = (
  assetTypes: AssetType[] = [],
): UseAssetsSortedByTypeResponse => {
  return useMemo(() => {
    return assetTypes?.reduce(
      (acc, assetType) => {
        if (!isFiat(assetType.id) && assetType.depositAddress) {
          acc.supportedStablecoins.push({ ...assetType, min: assetType.withdrawMin } as AssetType);
        } else if (
          isFiat(assetType.id) &&
          assetType?.depositInstructions &&
          assetType?.depositInstructions?.length > 0
        ) {
          acc.supportedFiatCurrencies.push({
            ...assetType,
            balance: '0',
            min: assetType.withdrawMin,
          } as AssetType);
        } else if (assetType.presentShowcaseWallet) {
          acc.showcaseCurrencies.push({
            ...assetType,
            balance: '0',
            min: assetType.withdrawMin,
          } as AssetType);
        }
        return acc;
      },
      {
        showcaseCurrencies: [] as AssetType[],
        supportedFiatCurrencies: [] as AssetType[],
        supportedStablecoins: [] as AssetType[],
      },
    );
  }, [assetTypes]);
};
