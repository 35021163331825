import { Navigate, RouteObject, To } from 'react-router-dom';
import React from 'react';

export interface Path {
  absolute: string;
  relative: string;
}

export type Routes = {
  [path: string]: Path;
};

/**
 * Returns path object containing absolute and relative values for a given path.
 *
 * Eg. for `/dashboard` set as root and `controls` as relativePath, the util will return object:
 * ```js
 * {
 *   absolute: '/dashboard/controls',
 *   relative: 'controls',
 * }
 * ```
 *
 * Returned absolute path will *always* start with `/`.
 *
 * @param root root element that relative path should be built on
 * @param relativePath path part
 * @returns Path object
 */
export const getPathObject = (root: string | Path, relativePath: string): Path => {
  let rootAbsolutePath = root && typeof root === 'object' ? root.absolute : root;
  rootAbsolutePath = rootAbsolutePath || '/';

  const isTopLevelRoot = rootAbsolutePath === '/';
  let absolute;
  if (!relativePath) {
    absolute = rootAbsolutePath;
  } else if (isTopLevelRoot) {
    absolute = `${rootAbsolutePath}${relativePath}`;
  } else {
    absolute = `${rootAbsolutePath}/${relativePath}`;
  }

  return {
    absolute,
    relative: relativePath,
  };
};

/**
 * Helper for generating redirection elements to be used with React Router
 *
 * @param redirectionTarget path to redirect to
 * @returns generated redirection elements
 */
export const getRootAndWildcardRedirectionObjects = (redirectionTarget: To): RouteObject[] => [
  {
    element: <Navigate replace to={redirectionTarget} />,
    index: true,
  },
  {
    element: <Navigate replace to={redirectionTarget} />,
    path: '*',
  },
];
