import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import React, { FC } from 'react';

import { KeyData, Organization } from 'store/api/api.types';
import { useGetKeysQuery } from 'store/api/platformApi';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';

const columnHelper = createColumnHelper<KeyData>();

const columns = [
  columnHelper.accessor(row => row.id, {
    cell: info => <Text variant='legacyWalletAddressSmall'>{info.getValue()} </Text>,
    header: 'Key ID',
    id: 'keyId',
  }),
  columnHelper.accessor(row => row.attributes.createdAt, {
    cell: info => format(parseISO(info.getValue()), 'y-MM-dd'),
    header: 'Created',
    id: 'createdAt',
  }),
  columnHelper.accessor(row => row.attributes.updatedAt, {
    cell: info => format(parseISO(info.getValue()), 'y-MM-dd'),
    header: 'Updated',
    id: 'updatedAt',
  }),
];

const ApiKeysTable: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const { data: keys, isLoading: isLoadingKeys } = useGetKeysQuery({
    organizationId,
  });

  if (isLoadingKeys) {
    return <Spinner />;
  }

  return (
    <div>
      <Table
        options={{
          columns,
          data: keys || [],
          getCoreRowModel: getCoreRowModel(),
        }}
      />
      {keys?.length === 0 && (
        <Text align='center' marginTop={5} variant='bodyCopySmall'>
          No API Keys Created
        </Text>
      )}
    </div>
  );
};

export default ApiKeysTable;
