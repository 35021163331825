import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaginationParams } from 'constants/queryParams';

import { Links, PaginationMode } from '../store/api/api.types';

const usePaginationLinks = (
  mode: PaginationMode,
  links?: Links,
  pageSize?: number,
): {
  handleOnNextPage?: () => void;
  handleOnPreviousPage?: () => void;
  handleOnSelectPage?: (pageNumber: number) => void;
} => {
  const navigate = useNavigate();
  const nextPageValue = useMemo(() => links?.next, [links]);
  const prevPageValue = useMemo(() => links?.prev, [links]);
  const nextPageParam =
    mode === PaginationMode.Cursor ? PaginationParams.PageAfter : PaginationParams.PageNumber;
  const prevPageParam =
    mode === PaginationMode.Cursor ? PaginationParams.PageAfter : PaginationParams.PageNumber;

  const pageSizeQueryParam = pageSize ? `&${PaginationParams.PageSize}=${pageSize}` : '';

  const handleOnNextPage = () => {
    navigate(`${window.location.pathname}?${nextPageParam}=${nextPageValue}${pageSizeQueryParam}`);
  };

  const handleOnPreviousPage = () => {
    navigate(`${window.location.pathname}?${prevPageParam}=${prevPageValue}${pageSizeQueryParam}`);
  };

  const handleOnSelectPage = (pageNumber: number) => {
    navigate(
      `${window.location.pathname}?${PaginationParams.PageNumber}=${pageNumber}${pageSizeQueryParam}`,
    );
  };

  return {
    handleOnNextPage: nextPageValue ? handleOnNextPage : undefined,
    handleOnPreviousPage: prevPageValue ? handleOnPreviousPage : undefined,
    handleOnSelectPage: mode === PaginationMode.Offset ? handleOnSelectPage : undefined,
  };
};

export default usePaginationLinks;
