import { getPathObject as path } from './utils';

const ROOT = path('', '');

export const ROOT_ROUTES = {
  APP: path(ROOT, 'app'),
  LOGIN: path(ROOT, 'login'),
  LOGOUT: path(ROOT, 'logout'),
  PRINT: path(ROOT, 'print'),
  ROOT,
  SIGNUP: path(ROOT, 'signup'),
  UNDER_MAINTENANCE: path(ROOT, 'under-maintenance'),
};

export const APP_ROUTES = {
  EMAIL_VERIFICATION_REQUIRED: path(ROOT_ROUTES.APP, 'email-verification'),
  INVITES: path(ROOT_ROUTES.APP, 'invites'),
  ORGANIZATIONS: path(ROOT_ROUTES.APP, 'organizations'),
};

export const ORGANIZATIONS_ROUTES = {
  CREATE_ORGANIZATION: path(APP_ROUTES.ORGANIZATIONS, 'create-organization'),
  ORGANIZATION: path(APP_ROUTES.ORGANIZATIONS, ':organizationId'),
};

export const ORGANIZATION_ROUTES = {
  API_KEYS: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'api-keys'),
  DASHBOARD: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'dashboard'),
  MEMBERS: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'members'),
  PENDING_VERIFICATION: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'pending-verification'),
  SENDERS_RECIPIENTS: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'senders-recipients'),
  SEND_MONEY: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'send-money'),
  SETTINGS: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'settings'),
  TRANSACTIONS: path(ORGANIZATIONS_ROUTES.ORGANIZATION, 'transactions'),
};

export const ORGANIZATION_TRANSACTIONS_ROUTES = {
  ALL: path(ORGANIZATION_ROUTES.TRANSACTIONS, 'all'),
  COMPLETED: path(ORGANIZATION_ROUTES.TRANSACTIONS, 'completed'),
  PENDING: path(ORGANIZATION_ROUTES.TRANSACTIONS, 'pending'),
};

export const ORGANIZATION_MEMBERS_ROUTES = {
  ACTIVE: path(ORGANIZATION_ROUTES.MEMBERS, 'active'),
  INVITE: path(ORGANIZATION_ROUTES.MEMBERS, 'invite'),
  INVITED: path(ORGANIZATION_ROUTES.MEMBERS, 'invited'),
};

export const INVITES_ROUTES = {
  INVITE: path(APP_ROUTES.INVITES, ':inviteHash'),
};

export const INVITE_ROUTES = {
  ACCEPT: path(INVITES_ROUTES.INVITE, 'accept'),
  EXPIRED: path(INVITES_ROUTES.INVITE, 'expired'),
};

export const SEND_MONEY_ROUTES = {
  TRANSACTION_DETAIL: path(ORGANIZATION_ROUTES.SEND_MONEY, ':transactionId'),
};

export const PRINT_ROUTES = {
  PAYMENT_RECEIPTS: path(ROOT_ROUTES.PRINT, 'payment-receipts'),
};
