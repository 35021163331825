import React, { ReactElement, useEffect } from 'react';

import LoaderView from 'components/views/LoaderView/LoaderView';
import auth0Client from 'services/auth0Client';

const LogoutView = (): ReactElement => {
  useEffect(() => {
    auth0Client.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }, []);

  return <LoaderView label='Logging out...' />;
};

export default LogoutView;
