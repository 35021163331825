import { CSSTransition } from 'react-transition-group';
import React, { FC, useRef } from 'react';

import { DEFAULT_TRANSITION_TIME } from 'constants/constants';

import BackdropProps from './Backdrop.types';
import styles from './Backdrop.module.scss';

const Backdrop: FC<BackdropProps> = ({
  justifyContent = 'center',
  useAnimationForChildNodes = false,
  children,
  dataTestId,
  isVisible = false,
  onBackgroundClick,
}) => {
  const nodeRef = useRef(null);

  const handleBackgroundClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (onBackgroundClick && event.target === event.currentTarget) {
      onBackgroundClick(event);
    }
  };

  return (
    <CSSTransition
      classNames={{
        enter: styles['root--enter'],
        enterActive: styles['root--enterActive'],
        exit: styles['root--exit'],
        exitActive: styles['root--exitActive'],
      }}
      in={isVisible}
      nodeRef={nodeRef}
      timeout={DEFAULT_TRANSITION_TIME}
      unmountOnExit
    >
      <div className={styles.wrapper}>
        {!useAnimationForChildNodes && (
          <div
            className={styles.childrenWrapper}
            onClick={handleBackgroundClick}
            style={{ justifyContent }}
          >
            {children}
          </div>
        )}
        <div
          ref={nodeRef}
          className={styles.root}
          data-testid={dataTestId}
          onClick={useAnimationForChildNodes ? () => {} : handleBackgroundClick}
          style={{ justifyContent }}
        >
          {useAnimationForChildNodes && children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default Backdrop;
