import { createContext } from 'react';

import { CreateTransactionContextType } from './CreateTransactionContext.types';
import { getContextFromProvider } from './context.utils';

const CreateTransactionContext = createContext<CreateTransactionContextType | undefined>(undefined);

export const useCreateTransactionContext =
  getContextFromProvider<CreateTransactionContextType>(CreateTransactionContext);

export default CreateTransactionContext;
