import { generatePath, useNavigate } from 'react-router-dom';
import React, { ReactElement, useMemo, useState } from 'react';

import { ORGANIZATION_MEMBERS_ROUTES } from 'router/constants';
import { isGenericErrorType, mapFieldErrorMessage } from 'store/api/api.utils';
import { showError } from 'services/notificationService';
import { useCreateOrganizationMutation, useGetOrganizationsQuery } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import InputField from 'components/core/Form/InputField/InputField';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';

import styles from './CreateOrganizationView.module.scss';

const CreateOrganizationView = (): ReactElement => {
  const navigate = useNavigate();
  const { isLoading: isFetchingOrganizations } = useGetOrganizationsQuery();
  const [newOrganizationName, setNewOrganizationName] = useState('');
  const [createOrganization, { isLoading: isCreatingOrganization, error }] =
    useCreateOrganizationMutation();

  const fieldErrorMessage = useMemo(
    () =>
      mapFieldErrorMessage('name', error, {
        DUPLICATED_VALUE: 'This organization name already exists.',
      }),
    [error],
  );

  const handleCreateOrganization = async e => {
    e.preventDefault();
    const result = await createOrganization({ name: newOrganizationName });
    if (!('error' in result)) {
      const newOrgPath = generatePath(ORGANIZATION_MEMBERS_ROUTES.INVITE.absolute, {
        organizationId: result.data.id,
      });
      navigate(newOrgPath);
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to create an organization. Please try again later.');
    }
  };

  if (isFetchingOrganizations) {
    return <Spinner />;
  }

  return (
    <div className={styles.root} data-testid='CreateOrganizationView'>
      <Text align='center' className={styles.header} variant='legacyTitle'>
        Create Your Organization
      </Text>
      <Text align='center' marginBottom={2}>
        Enter a name for your organization to get started.
      </Text>
      <form className={styles.form} onSubmit={handleCreateOrganization}>
        <InputField
          className={styles.input}
          error={fieldErrorMessage}
          isDisabled={isCreatingOrganization}
          name='newOrganizationName'
          onChange={({ target: { value } }) => setNewOrganizationName(value)}
          placeholder='Eg. Your Company Inc.'
        />
        <Button
          isLoading={isCreatingOrganization}
          label='Create Organization'
          type='submit'
          variant='primary'
        />
      </form>
    </div>
  );
};

export default CreateOrganizationView;
