import React, { FC } from 'react';
import cx from 'classnames';

import { getUriIdAndChainId } from 'utils/format';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';

import { AssetPillProps } from './AssetPill.types';
import styles from './AssetPill.module.scss';

const AssetPill: FC<AssetPillProps> = ({
  assetType,
  className,
  variant = 'default',
  onClick,
  ...props
}) => {
  if (!assetType) {
    return null;
  }
  const { id, iconName } = assetType;
  return (
    <Button
      className={cx(
        className,
        styles.assetPill,
        variant && styles[`variant--${variant}`],
        !onClick && styles.noHover,
      )}
      label={getUriIdAndChainId(id)}
      LeadingIcon={<Icon className={styles.circle} iconName={iconName} size={1.8} />}
      onClick={onClick}
      variant='dropdownOpaque'
      {...props}
    />
  );
};

export default AssetPill;
