import { createContext } from 'react';

import { CurrentUserContextType } from './CurrentUserContext.types';
import { getContextFromProvider } from './context.utils';

const CurrentUserContext = createContext<CurrentUserContextType | undefined>(undefined);

export const useCurrentUser = getContextFromProvider<CurrentUserContextType>(CurrentUserContext);

export default CurrentUserContext;
