import React, { FC } from 'react';
import cx from 'classnames';

import ModalContentProps from './ModalContent.types';
import styles from './ModalContent.module.scss';

const ModalContent: FC<ModalContentProps> = ({
  children,
  className,
  dataTestId,
  variant = 'default',
}) => (
  <section
    className={cx(styles.root, styles[`variant--${variant}`], className)}
    data-testid={dataTestId}
  >
    {children}
  </section>
);

export default ModalContent;
