import { Environment } from 'constants/constants';
import removeTrailingSlash from 'utils/removeTrailingSlash';

export const apiUrl = removeTrailingSlash(process.env.REACT_APP_API_URL || 'http://localhost:4000');

export const commitHash = process.env.REACT_APP_COMMIT_SHA;
export const environment: Environment = (process.env.REACT_APP_ENV as Environment) || 'local';

export const auth0 = {
  audience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
};

export const sentry = {
  // Sentry DSN URL - if empty, Sentry is disabled.
  dsn: process.env.REACT_APP_SENTRY_DSN || '',
  // Percent of user sessions during which an error occured to record
  // as Session Replay.
  replaysOnErrorSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || '1.0',
  ),
  // Percent of normal user sessions to record as Session Replay.
  replaysSessionSampleRate: parseFloat(
    process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || '0.1',
  ),
  // Percent of transactions to capture. 100% by default.
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '1.0'),
};

export const googleTagManager = {
  auth: process.env.REACT_APP_GTM_AUTH || '',
  cookiesWin: process.env.REACT_APP_GTM_COOKIES_WIN || '',
  id: process.env.REACT_APP_GTM_ID || '',
  preview: process.env.REACT_APP_GTM_PREVIEW || '',
};
