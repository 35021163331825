import React, { FC } from 'react';
import cx from 'classnames';

import { BoxProps } from 'components/core/Box/Box.types';
import Box from 'components/core/Box/Box';

import styles from './List.module.scss';

const List: FC<BoxProps> = ({ children, className, ...props }) => (
  <Box className={cx(styles.root, className)} {...props}>
    {children}
  </Box>
);

export default List;
