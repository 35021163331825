/**
 * Takes an object and returns a merged string that can be used as a query string.
 *
 * @param params object defining query params
 * @param config configuration object with following options:
 * - `beginsWith` - defines the prefix for the query string
 * - `skipFalsy` - if true, skips params with values that cast to false. If false, will only skip undefined values.
 *
 * @returns params merged into a single query string
 */
export default function getQueryStringFromParams(
  params: { [param: string]: any } = {},
  config: { beginsWith?: string; parentKey?: string; skipFalsy?: boolean } = {
    beginsWith: '?',
    skipFalsy: false,
  },
): string {
  const { parentKey, skipFalsy, beginsWith } = config;
  const queryStringParts: string[] = [];

  Object.keys(params).forEach(key => {
    const value = params[key];
    const fullKey = parentKey ? `${parentKey}[${key}]` : encodeURIComponent(key);

    if (value === undefined || (skipFalsy && (value === null || value === '' || value === false))) {
      return;
    }

    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
      const nestedConfig = { ...config, parentKey: fullKey };
      const nestedQueryString = getQueryStringFromParams(value, nestedConfig);
      if (nestedQueryString) {
        queryStringParts.push(nestedQueryString);
      }
    } else {
      queryStringParts.push(`${fullKey}=${encodeURIComponent(value)}`);
    }
  });

  let queryString = queryStringParts.join('&');
  if (!parentKey && beginsWith) {
    queryString = queryString ? `${beginsWith}${queryString}` : '';
  }

  return queryString;
}
