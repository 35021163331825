import { LOCAL_STORAGE_PREFIX } from 'constants/constants';

/**
 * A simple wrapper around the browser's localStorage API that prefixes
 * all keys with 'conduit-' and provides a simple interface for getting,
 * setting, and removing items from localStorage including the ability to
 * store and retrieve objects.
 */
const localStorageService = () => {
  const remove = (key: string): void => {
    localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${key}`);
  };

  const get = (key: string): any | null => {
    const item = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${key}`);
    if (!item) {
      return null;
    }

    try {
      return JSON.parse(item);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return null;
    }
  };

  const set = (key: string, value: any): void => {
    localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${key}`, JSON.stringify(value));
  };

  return {
    get,
    remove,
    set,
  };
};

export default localStorageService();
