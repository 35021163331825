import { TimeRange } from './isCurrentTimeInRange.types';

const SATURDAY = 6;
const SUNDAY = 0;

export const isCurrentTimeInRange = (config: TimeRange): boolean => {
  const { start, stop, timezone, areWeekendsIncluded = true } = config;

  const now = new Date();
  const timezoneOffset = now.toLocaleString('en', { timeZone: timezone });
  const localTime = new Date(timezoneOffset);
  const currentDay = localTime.getDay();
  const currentHours = localTime.getHours();
  const currentMinutes = localTime.getMinutes();

  const startTimeInMinutes = start.hour * 60 + start.minute;
  const stopTimeInMinutes = stop.hour * 60 + stop.minute;
  const currentTimeInMinutes = currentHours * 60 + currentMinutes;
  const isWithinOperatingHours =
    currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes <= stopTimeInMinutes;

  const isWeekend = [SATURDAY, SUNDAY].includes(currentDay);
  const isWithinAllowedDays = areWeekendsIncluded || !isWeekend;

  return isWithinOperatingHours && isWithinAllowedDays;
};
