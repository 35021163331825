import { Slide, ToastContainer, ToastContainerProps } from 'react-toastify';
import React, { FC } from 'react';

import { crossmark } from 'components/core/Svg/icons';
import Svg from 'components/core/Svg/Svg';

import styles from './NotificationsContainer.module.scss';

const NotificationsContainer: FC<ToastContainerProps> = props => (
  <ToastContainer
    closeButton={
      props.closeOnClick !== false && (
        <Svg className={styles.closeButtonIcon} img={crossmark} size={1.3} />
      )
    }
    position='top-center'
    transition={Slide}
    {...props}
  />
);

export default NotificationsContainer;
