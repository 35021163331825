import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import CopyToClipboardButton from 'components/core/CopyToClipboardButton/CopyToClipboardButton';
import Text from 'components/core/Text/Text';

import { ListItemProps } from './ListItem.types';
import styles from './ListItem.module.scss';

const ListItem: FC<ListItemProps> = ({
  children,
  className,
  label,
  useSingleCellListItem,
  valueForCopyButton,
  ...props
}) => {
  if (useSingleCellListItem) {
    return <Box className={cx(styles.singleCellListItem, className)}>{children}</Box>;
  }

  return (
    <Box className={cx(styles.root, className)} {...props}>
      <Text className={styles.label} Tag='label' variant='labelSmallSubLabel'>
        {label}
      </Text>
      <div className={styles.valueCell}>
        {valueForCopyButton && (
          <div className={styles.copyButton}>
            <CopyToClipboardButton valueToCopy={valueForCopyButton} />
          </div>
        )}
        <div className={styles.valueContent}>{children}</div>
      </div>
    </Box>
  );
};

export default ListItem;
