import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import Icon from 'components/core/Icon/Icon';

import { ListItemWithIconProps } from './ListItemWithIcon.types';
import styles from './ListItemWithIcon.module.scss';

const ListItemWithIcon: FC<ListItemWithIconProps> = ({
  children,
  className,
  iconName = 'arrowRight',
  ...props
}) => (
  <Box className={cx(styles.root, className)} {...props}>
    <div className={styles.bulletCell}>
      <Icon iconName={iconName} size={1.1} />
    </div>
    <div className={styles.valueCell}>{children}</div>
  </Box>
);

export default ListItemWithIcon;
