export default function formatBytes(bytes: number, showDecimals = false): string {
  const decimals = showDecimals ? 2 : 0;
  if (bytes < 1024) {
    return `${bytes} Bytes`;
  }
  if (bytes < 1048576) {
    return `${(bytes / 1024).toFixed(decimals)} KB`;
  }
  if (bytes < 1073741824) {
    return `${(bytes / 1048576).toFixed(decimals)} MB`;
  }
  return `${(bytes / 1073741824).toFixed(decimals)} GB`;
}
