export const COL_BUSINESS_IDENTIFICATION_TYPES = [
  {
    label: 'Tax ID (NIT)',
    value: 'Tax ID (NIT)',
  },
];

export const COL_PERSON_IDENTIFICATION_TYPES = [
  {
    label: 'National ID (CC)',
    value: 'National ID (CC)',
  },
  {
    label: 'Foreigner ID (CE)',
    value: 'Foreigner ID (CE)',
  },
  ...COL_BUSINESS_IDENTIFICATION_TYPES,
  {
    label: 'Passport',
    value: 'Passport',
  },
];

export const MEX_BUSINESS_IDENTIFICATION_TYPES = [
  {
    label: 'RFC',
    value: 'RFC',
  },
];

export const MEX_PERSON_IDENTIFICATION_TYPES = [
  ...MEX_BUSINESS_IDENTIFICATION_TYPES,
  {
    label: 'CURP',
    value: 'CURP',
  },
];

export const BRA_BUSINESS_IDENTIFICATION_TYPE_OPTION = {
  label: 'CNPJ',
  value: 'CNPJ',
};

export const BRA_PERSON_IDENTIFICATION_TYPE_OPTION = {
  label: 'CPF',
  value: 'CPF',
};
