import debugService from 'services/debugService';
import getQueryStringFromParams from 'utils/getQueryStringFromParams';
import insertSnippet from 'utils/insertSnippet';

import { DataLayerEvent, GtmParamsType, GtmServiceType } from './gtmService.types';

const gtmService = (): GtmServiceType => {
  let dataLayerName: string | null = null;
  let dataLayerRef: Record<string, any>[] = [];
  let isInstalled = false;

  /**
   * Method that injects the `script` snippet into the head
   *
   * @param gtmId GTM ID
   * @param gtmParams additional GTM pameters
   * @param dataLayerNameArg name of the global dataLayer variable
   */
  const injectHeadScript = (
    gtmId: string,
    gtmParams: GtmParamsType,
    dataLayerNameArg = 'dataLayer',
  ) => {
    const queryStringParams = getQueryStringFromParams(gtmParams, {
      beginsWith: '&',
      skipFalsy: true,
    });
    const codeSnippet = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl${
      queryStringParams ? `+'${queryStringParams}'` : ''
    };f.parentNode.insertBefore(j,f);})(window,document,'script','${dataLayerNameArg}','${gtmId}');`;

    insertSnippet({ snippet: codeSnippet, tagType: 'script', targetNode: document.head });
  };

  /**
   * Method that injects the 'noscript' snippet into the body that consists of configured GTM iframe element.
   *
   * @param gtmId GTM ID
   * @param gtmParams additional GTM pameters
   */
  const injectBodyScript = (gtmId: string, gtmParams: GtmParamsType) => {
    const scriptWrapperId = 'gtm-wrapper';
    insertSnippet({
      attrs: { id: scriptWrapperId },
      tagType: 'noscript',
      targetNode: document.body,
    });
    insertSnippet({
      attrs: {
        height: 0,
        src: `https://www.googletagmanager.com/ns.html?id=${gtmId}${getQueryStringFromParams(
          gtmParams,
          { skipFalsy: true },
        )}`,
        style: 'display: none; visibility: hidden;',
        width: 0,
      },
      tagType: 'iframe',
      targetNode: document.getElementById(scriptWrapperId) as HTMLElement,
    });
  };

  /**
   * Takes original object that's about to be pushed into the dataLayer
   * and, if it's an event, adds to it an additional callback that logs
   * when said event was successfully dispatched.
   *
   * @param originalData orignal data that's about to be pushed to dataLayer
   *
   * @returns data conditionally updated with logging callback
   */
  const registerEventLoggingCallback = (originalData: DataLayerEvent): DataLayerEvent => {
    const updatedData = { ...originalData };
    if ('event' in originalData) {
      // eslint-disable-next-line no-unused-vars
      const { event, ...filteredProps } = originalData;
      updatedData.eventCallback = () => {
        debugService.debug.success(
          'GtmService',
          `Event "${originalData.event}" dispatched successfully.`,
          filteredProps,
        );
        if (originalData.eventCallback) {
          originalData.eventCallback();
        }
      };
    }
    return updatedData;
  };

  /**
   * Sets initial dataLayer content.
   * Mounts GTM into the DOM.
   *
   * @param gtmId GTM tracker ID
   * @param initialDataLayer initial dataLayer values, set before GTM script mounts & executes
   * @param gtmParams additional params to be inserted into GTM JS script
   * @param dataLayerNameArg name of the global dataLayer variable
   */
  const mount = (
    gtmId,
    initialDataLayer: Record<string, any>[] = [],
    gtmParams: GtmParamsType = {},
    dataLayerNameArg = 'dataLayer',
  ) => {
    if (isInstalled) {
      throw new Error('GTM is already mounted in the DOM!');
    }

    dataLayerName = dataLayerNameArg;
    /**
     * Merge initial dataLayer value with collected dataLayer data from calls
     * that may have happened before GTM service script was initialized.
     */
    window[dataLayerName] = [...initialDataLayer, ...dataLayerRef];
    dataLayerRef = window[dataLayerName];

    injectHeadScript(gtmId, gtmParams, dataLayerName);
    injectBodyScript(gtmId, gtmParams);
    isInstalled = true;
    debugService.debug.success(
      'GtmService',
      `Manager ${gtmId} mounted successfully. Initial dataLayer set as:`,
      dataLayerRef,
    );
  };

  /**
   * Push data to registered dataLayer object.
   *
   * @param dataObj JSON data to be pushed
   */
  const pushToDataLayer = (dataObj: DataLayerEvent) => {
    dataLayerRef.push(registerEventLoggingCallback(dataObj));
  };

  /**
   * Shortcut for pushing GTM events
   *
   * @param name event name
   * @param meta event props
   */
  const pushEvent = ({ name, meta = {} }) => {
    pushToDataLayer({
      event: name,
      hitType: name,
      ...meta,
    });
    debugService.debug.success('GtmService', `Dispatching "${name}" event.`, meta);
  };

  return {
    getDataLayerName: () => dataLayerName,
    isInstalled: () => isInstalled,
    mount,
    pushEvent,
    pushToDataLayer,
  };
};

export default gtmService();
