import { generatePath, useLocation, useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { ORGANIZATION_ROUTES, ORGANIZATION_TRANSACTIONS_ROUTES } from 'router/constants';
import { PaginationMode } from 'store/api/api.types';
import {
  TRANSACTIONS_FILTER_COMPLETED,
  TRANSACTIONS_FILTER_PENDING,
} from 'components/views/app/organization/TransactionsView/TransactionsView.constants';
import { TabProps } from 'components/core/Tab/Tab.types';
import { download } from 'components/core/Svg/icons';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import { useGetTransactionSummariesQuery } from 'store/api/platformApi';
import { useTransactionReportExport } from 'hooks/useTransactionReportExport';
import BackToTopButton from 'components/core/BackToTopButton/BackToTopButton';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import NoResultsMessage from 'components/core/NoResultsMessage/NoResultsMessage';
import PaginationBar from 'components/core/PaginationBar/PaginationBar';
import Spinner from 'components/core/Spinner/Spinner';
import Svg from 'components/core/Svg/Svg';
import Tabs from 'components/core/Tabs/Tabs';
import TransactionsList from 'components/dedicated/organization/TransactionsList/TransactionsList';
import usePaginationLinks from 'hooks/usePaginationLinks';
import usePaginationQuery from 'hooks/usePaginationQuery';

import styles from './TransactionsView.module.scss';

const TransactionsView = (): ReactElement => {
  const { pathname } = useLocation();
  const { organizationId } = useParams() as { organizationId: string };
  const { handleOnLimitSelect, pageNumber, pageSize } = usePaginationQuery();
  const { data: organizationData } = useGetOrganizationQuery({ organizationId });
  const { data: pendingTransactions, isLoading: isLoadingPendingTransactions } =
    useGetTransactionSummariesQuery({
      organizationId,
      params: {
        filter: {
          status: TRANSACTIONS_FILTER_PENDING,
        },
        page: {
          mode: PaginationMode.Offset,
          number: '1',
          size: 101, // to get the count of pending transactions
        },
      },
    });
  const isFilteringByPending = useMemo(
    () => pathname.includes(ORGANIZATION_TRANSACTIONS_ROUTES.PENDING.relative),
    [pathname],
  );
  const transactionStatusFilterValue = useMemo(() => {
    if (pathname.includes(ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.relative)) {
      return TRANSACTIONS_FILTER_COMPLETED;
    }
    if (isFilteringByPending) {
      return TRANSACTIONS_FILTER_PENDING;
    }
    return undefined;
  }, [isFilteringByPending, pathname]);

  const canExportTransactionReport =
    organizationData?.platform?.attributes?.features?.clientTransactionsReportEnabled;

  const { exportTransactionReport, isExporting } = useTransactionReportExport(organizationId, {
    status: transactionStatusFilterValue,
  });

  const {
    data: transactionsSummaries,
    isLoading: isLoadingMainQuery,
    isFetching,
  } = useGetTransactionSummariesQuery({
    organizationId,
    params: {
      filter: transactionStatusFilterValue ? { status: transactionStatusFilterValue } : undefined,
      page: {
        mode: PaginationMode.Offset,
        number: pageNumber || undefined,
        size: pageSize.value,
      },
    },
  });
  const { handleOnNextPage, handleOnPreviousPage, handleOnSelectPage } = usePaginationLinks(
    PaginationMode.Offset,
    transactionsSummaries?.links,
    pageSize.value,
  );

  const isLoading = isLoadingMainQuery || isLoadingPendingTransactions;

  const transactionSummaries = transactionsSummaries?.data || [];

  const tabs: TabProps[] = [
    {
      label: 'All',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.ALL.absolute, {
        organizationId,
      }),
    },
    {
      count:
        (pendingTransactions?.data.length || 0) > 100
          ? '100+'
          : pendingTransactions?.data.length || 0,
      label: 'Pending',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.PENDING.absolute, {
        organizationId,
      }),
    },
    {
      label: 'Completed',
      to: generatePath(ORGANIZATION_TRANSACTIONS_ROUTES.COMPLETED.absolute, {
        organizationId,
      }),
    },
  ];

  if (isLoading) {
    return <Spinner flexGrow={1} size='large' />;
  }

  return (
    <>
      <Tabs isFullWidth marginTop={4} tabs={tabs}>
        {canExportTransactionReport && (
          <Box display='flex' marginBottom={3} marginLeft='auto'>
            <Button
              isDisabled={isExporting || isFetching || transactionSummaries.length === 0}
              isLoading={isExporting}
              label={isExporting ? 'Exporting...' : 'Export CSV'}
              LeadingIcon={<Svg img={download} size={1.8} />}
              onClick={() => exportTransactionReport()}
              size='small'
            />
          </Box>
        )}
      </Tabs>
      <div className={styles.listContainer}>
        <ContentSection className={styles.resultsWrapper}>
          {!isLoading && transactionSummaries.length > 0 && (
            <>
              {isFetching && <Spinner flexGrow={1} />}
              {!isFetching && transactionSummaries.length > 0 && (
                <>
                  <TransactionsList
                    organizationId={organizationId}
                    shouldSectionByMonths
                    transactionSummaries={transactionSummaries}
                  />
                  <BackToTopButton />
                  <PaginationBar
                    className={styles.paginationBar}
                    lastPageNumber={transactionsSummaries?.links?.last}
                    onLimitSelect={handleOnLimitSelect}
                    onNextPage={handleOnNextPage}
                    onPrevPage={handleOnPreviousPage}
                    onSelectPage={handleOnSelectPage}
                    pageNumber={pageNumber}
                    pageSize={pageSize}
                    resultsCount={transactionSummaries.length}
                  />
                </>
              )}
            </>
          )}
          {!isFetching && transactionSummaries.length === 0 && (
            <NoResultsMessage
              button={
                isFilteringByPending
                  ? undefined
                  : {
                      label: 'Send Money',
                      to: generatePath(ORGANIZATION_ROUTES.SEND_MONEY.absolute, {
                        organizationId,
                      }),
                    }
              }
              header={isFilteringByPending ? 'No Pending Transactions' : 'No Transactions Yet'}
              message={
                isFilteringByPending
                  ? 'You have no pending transactions at the moment. Any new transactions you initiate will appear here.'
                  : `It looks like you haven't made any transactions yet. Once a transaction is created, you'll be able to track its progress and view all the important details right here.`
              }
            />
          )}
        </ContentSection>
      </div>
    </>
  );
};

export default TransactionsView;
