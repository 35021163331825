import React, { FC, useMemo, useState } from 'react';

import { Identity, Organization } from 'store/api/api.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useCreateBankAccountMutation } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import modalService from 'services/modalService';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';
import { BankDetailsComponentId } from '../config/bankDetails.types';
import {
  areBankDetailsValid,
  defaultBankDetails,
  getBankTitle,
} from '../config/bankDetails.helpers';
import { getFilteredBankDetailFormValues } from '../config/bankDetails';
import FormBankDetails from '../FormBankDetails/FormBankDetails';

const ModalBankAccountCreate: FC<{
  identity: Identity;
  organizationId: Organization['id'];
}> = ({ identity, organizationId }) => {
  const [createBankAccount, { isLoading: isCreating }] = useCreateBankAccountMutation();

  const [bankDetails, setBankDetails] = useState<BankDetails>(defaultBankDetails);
  const setBankDetailsField = (
    field: keyof BankDetails | BankDetailsComponentId,
    value: string,
  ) => {
    const newBankDetails = {
      ...bankDetails,
      [field]: value,
    };
    setBankDetails(newBankDetails);
  };

  const bankTitle = useMemo(() => getBankTitle(bankDetails), [bankDetails]);

  const handleCreateBankAccount = async e => {
    e.preventDefault();

    if (!areBankDetailsValid(bankDetails)) {
      return;
    }

    const result = await createBankAccount({
      data: {
        attributes: getFilteredBankDetailFormValues(bankDetails),
        relationships: {
          identities: {
            data: [{ id: identity.id || '', type: 'identity' }],
          },
        },
        type: 'bank-account',
      },
      organizationId,
    });

    if (!('error' in result)) {
      showSuccess(`You have successfully added ${bankTitle} as a new bank.`);
      modalService.closeCurrentModal();
    } else if (isGenericErrorType(result.error)) {
      showError(
        `${bankTitle} was unable to be successfully added. Please try again. If the issue continues, please contact support.`,
      );
    }
  };

  return (
    <form onSubmit={handleCreateBankAccount}>
      <ModalContent variant='noVerticalPadding'>
        <FormBankDetails
          bankDetails={bankDetails}
          isProcessing={isCreating}
          setBankDetailsField={setBankDetailsField}
        />
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button label='Cancel' onClick={() => modalService.closeCurrentModal()} variant='textCta' />
        <Button
          isDisabled={!areBankDetailsValid(bankDetails)}
          isLoading={isCreating}
          label='Add'
          type='submit'
          variant='primary'
        />
      </ModalContent>
    </form>
  );
};

export default ModalBankAccountCreate;
