import { AssetType } from 'constants/assetTypes.types';
import { LiquidityLimits } from 'store/api/api.types';

import { LiquidityLimitStatus } from './LiquidityLimitMessage.types';

export const getLiquidityLimitStatus = (
  liquidityLimits: LiquidityLimits,
  targetAmount: string | undefined,
  targetAssetType: AssetType | undefined,
): LiquidityLimitStatus => {
  const liquidityLimit = liquidityLimits.find(
    limit => limit.attributes.limitEnabled && limit.attributes.assetType === targetAssetType?.id,
  );

  if (liquidityLimit) {
    const limit = Number(liquidityLimit.attributes.liquidityLimit) / 1000000;
    const utilization = Number(liquidityLimit.attributes.liquidityUtilization) / 1000000;
    const amount = targetAmount ? Number(targetAmount) : Number(0);

    if (utilization >= limit) {
      return {
        isOverLimit: true,
        message: {
          primary: `You’ve reached your daily limit of ${limit.toLocaleString()} ${targetAssetType?.ticker}.`,
          secondary: `Please try a different currency or try again after the limit resets at 9:15 AM ET.`,
        },
      };
    }

    if (utilization + amount > limit) {
      return {
        isOverLimit: true,
        message: {
          primary: `The quoted ${targetAssetType?.ticker} amount exceeds your remaining daily limit of ${(limit - utilization).toLocaleString()} ${targetAssetType?.ticker}.`,
          secondary: `Please enter a lower amount, or try again after the limit resets to ${limit.toLocaleString()} ${targetAssetType?.ticker} at 9:15 AM ET.`,
        },
      };
    }
  }

  return {
    isOverLimit: false,
  };
};
