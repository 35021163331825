import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ERROR_CODES } from 'constants/errorCodes';
import { showError } from 'services/notificationService';

export const showSpecificError = (error: FetchBaseQueryError): void => {
  const errorData: any = error.data;

  if (errorData?.errors && errorData?.errors?.length > 0) {
    const errorCode = errorData.errors[0].code;
    if (errorCode in ERROR_CODES) {
      showError(ERROR_CODES[errorCode]);
    } else {
      showError(errorData.errors[0].title);
    }
  } else {
    showError('There was an error processing your request');
  }
};
