import React, { FC } from 'react';

import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

const ModalIdentityDuplicate: FC<{
  createIdentity: () => {};
  identityName: string;
}> = ({ createIdentity, identityName }) => {
  return (
    <>
      <ModalContent variant='noBottomPadding'>
        <Text marginBottom={4}>
          A sender & recipient with the same name and country already exists.
        </Text>
        <Text marginBottom={4}>
          To avoid mix-ups or mistakes in your transactions, please double-check and confirm if you
          want to create <b>{identityName}</b>.
        </Text>
      </ModalContent>
      <ModalContent variant='footerButtons'>
        <Button label='Cancel' onClick={() => modalService.closeCurrentModal()} variant='textCta' />
        <Button label='Confirm' onClick={() => createIdentity()} variant='primary' />
      </ModalContent>
    </>
  );
};

export default ModalIdentityDuplicate;
