import React, { FC, useMemo } from 'react';

import { ApiTransactionSummaryAttributes } from 'store/api/api.types';
import { formatAssetType } from 'utils/format';
import Icon from 'components/core/Icon/Icon';
import List from 'components/core/List/List';
import ListItem from 'components/core/ListItem/ListItem';
import Text from 'components/core/Text/Text';

const OffRampTransaction: FC<{
  showTitle?: boolean;
  transactionAttributes: ApiTransactionSummaryAttributes;
}> = ({ showTitle, transactionAttributes }) => {
  const destinationBank = useMemo(() => {
    return transactionAttributes.destination?.bank;
  }, [transactionAttributes]);

  return (
    <>
      <Text align='center'>
        {showTitle && (
          <>
            <Icon iconName='inProgress' size={4} />
            <Text marginTop={3} variant='sectionHeaderLarge'>
              Transaction Initiated
            </Text>
          </>
        )}
        <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
          Your transaction is initiated. Reviews may result in delays or fund holds. Check the
          Transaction page for updates.
        </Text>
        <Text align='center' marginBottom={5} variant='bodyCopySmall'>
          <b>{formatAssetType(transactionAttributes.destination)}</b> will be sent to the recipient
          below.
        </Text>
      </Text>
      <List>
        <ListItem label='Recipient'>{destinationBank?.nameOnBankAccount}</ListItem>
        <ListItem label='Bank Name'>{destinationBank?.bankName}</ListItem>
        <ListItem label='Account Number'>{destinationBank?.accountNumber}</ListItem>
        {transactionAttributes.reference && (
          <ListItem label='Reference'>{transactionAttributes.reference}</ListItem>
        )}
      </List>
    </>
  );
};

export default OffRampTransaction;
