import React, { FC, useMemo, useState } from 'react';

import { KeyData } from 'store/api/api.types';
import Button from 'components/core/Button/Button';
import CopyToClipboardButton from 'components/core/CopyToClipboardButton/CopyToClipboardButton';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

import styles from './ModalCreateKeySuccessful.module.scss';

export const modalCreateKeySuccessfulTitle = 'API Key Successfully Created';

const ModalCreateKeySuccessful: FC<{ createdKey: KeyData }> = ({ createdKey }) => {
  const [isSecretShown, setIsSecretShown] = useState(false);

  const displayedSecretValue = useMemo(() => {
    if (isSecretShown) {
      return createdKey.attributes.secret || '';
    }
    return '*****************************';
  }, [createdKey.attributes.secret, isSecretShown]);

  return (
    <>
      <ModalContent>
        <Noticebox variant='warning'>
          <Text>
            This is the <strong>ONLY</strong> time that your key secret can be viewed. You cannot
            recover it later. Please write it down.
          </Text>
        </Noticebox>
        <div className={styles.property}>
          <Text variant='legacyBodyEmphasized'>Key ID</Text>
          <div className={styles.row}>
            <Text overflow='breakWord' variant='legacyWalletAddress'>
              {createdKey.id}
            </Text>
            <CopyToClipboardButton valueToCopy={createdKey.id} />
          </div>
        </div>
        <div className={styles.property}>
          <Text variant='legacyBodyEmphasized'>Key Secret</Text>
          <div className={styles.row}>
            <Text className={styles.secretValue} overflow='breakWord' variant='legacyWalletAddress'>
              {displayedSecretValue}
            </Text>
            <Button
              label={isSecretShown ? 'Hide' : 'Show'}
              onClick={() => {
                setIsSecretShown(!isSecretShown);
              }}
              size='small'
              variant='hyperlink'
            />
            <CopyToClipboardButton valueToCopy={createdKey.attributes.secret || ''} />
          </div>
        </div>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          label='Close'
          onClick={() => {
            modalService.closeCurrentModal();
          }}
          variant='tertiary'
        />
      </ModalContent>
    </>
  );
};

export default ModalCreateKeySuccessful;
