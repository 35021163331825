import React, { FC } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { isCurrentTimeInRange } from 'utils/isCurrentTimeInRange';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

const OperatingHoursMessage: FC<{ assetType?: AssetType }> = ({ assetType }) => {
  if (assetType?.operatingHours && !isCurrentTimeInRange(assetType.operatingHours)) {
    return (
      <Noticebox marginTop={4} variant='warning'>
        <Text>{assetType?.operatingHours.message}</Text>
      </Noticebox>
    );
  }

  return null;
};

export default OperatingHoursMessage;
