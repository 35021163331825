import { ClientTag, Organization } from 'store/api/api.types';

export const hasColumnAgreementClientTags = (organization: Organization): boolean => {
  const tags = organization?.platform?.attributes?.tags || [];
  return tags.includes(ClientTag.COLUMN) && tags.includes(ClientTag.NESTED);
};

export const checkIfHasAgreedToColumnAgreement = (
  organization: Organization | undefined,
): boolean => {
  if (organization && hasColumnAgreementClientTags(organization)) {
    const columnAgreement = organization?.agreements.find(
      agreement => agreement.agreementId === 'COLUMN' && agreement.acceptedAt !== null,
    );

    return !!columnAgreement;
  }

  return true;
};
