import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';

import { platformApi } from './api/platformApi';
import { rootApi } from './api/rootApi';
import authMiddleware from './auth/auth.middleware';
import authSlice from './auth/auth.slice';

const store = configureStore({
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      rootApi.middleware as Middleware,
      platformApi.middleware as Middleware,
      authMiddleware,
    ),
  reducer: combineReducers({
    [rootApi.reducerPath]: rootApi.reducer,
    [platformApi.reducerPath]: platformApi.reducer,
    auth: authSlice,
  }),
});
setupListeners(store.dispatch);

if (window.Cypress) {
  window.store = store;
}

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
