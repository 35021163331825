import React, { FC } from 'react';

import Icon from 'components/core/Icon/Icon';

import { CurrencyItemProps } from './CurrencyItem.types';
import styles from './CurrencyItem.module.scss';

const CurrencyItem: FC<CurrencyItemProps> = ({ assetType }) => (
  <div className={styles.root}>
    <Icon iconName={assetType.iconName} size={1.8} />
    <span>
      <b>{assetType.ticker}</b> - {assetType.name}
    </span>
  </div>
);

export default CurrencyItem;
