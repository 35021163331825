import React, { FC, useEffect } from 'react';
import cx from 'classnames';

import ModalHeader from 'components/core/Modal/ModalHeader/ModalHeader';

import ModalBoxProps from './ModalBox.types';
import styles from './ModalBox.module.scss';

const ModalBox: FC<ModalBoxProps> = ({
  children,
  className,
  dataTestId,
  disableScrollOnLargeScreen = false,
  nodeRef,
  onClosed,
  title,
  type = 'default',
}) => {
  useEffect(() => {
    return () => {
      if (onClosed) {
        onClosed();
      }
    };
  }, [onClosed]);

  return (
    <div
      ref={nodeRef}
      className={cx(styles.root, styles[`type--${type}`], className)}
      data-testid={dataTestId}
    >
      {title && <ModalHeader className={styles.header} title={title} />}
      <div
        className={cx(
          styles.contentWrapper,
          disableScrollOnLargeScreen && styles.disableScrollOnLargeScreen,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ModalBox;
