import { fromWei, toBigInt, toWei } from 'web3-utils';

import { AssetType, AssetTypePairRule } from 'constants/assetTypes.types';
import { formatWeiToHumanReadable, getUriId, isFiat } from 'utils/format';

import {
  AmountValidationFunction,
  AmountValidationResult,
  AssetTypeBalanceValidity,
} from './AssetTypeField.types';

export const getAssetTypePairRule = (
  assetType?: AssetType,
  assetTypePair?: AssetType,
): AssetTypePairRule | undefined =>
  assetTypePair &&
  assetType?.assetTypePairRules?.find(pair => pair.assetTypes.includes(assetTypePair.id));

const getUserBalanceLimitValidationError = (
  weiValue: string,
  assetType: AssetType,
): AmountValidationResult | null => {
  if (!isFiat(assetType) && (!assetType.max || toBigInt(weiValue) > assetType.max)) {
    return {
      errorMessage: `Insufficient ${getUriId(assetType)} balance. Add ${getUriId(assetType)} and try again.`,
      status: AssetTypeBalanceValidity.MoreThanMax,
    };
  }
  return null;
};

export const getAssetPairRuleValidationError = (
  weiValue: string,
  assetTypePairRule?: AssetTypePairRule,
): AmountValidationResult | null => {
  if (assetTypePairRule && toBigInt(weiValue) > assetTypePairRule.max) {
    return {
      errorMessage: assetTypePairRule.maxMessage,
      status: AssetTypeBalanceValidity.MoreThanMax,
    };
  }
  return null;
};

export const getMaxQuoteValidationError = (
  weiValue: string,
  assetType: AssetType,
): AmountValidationResult | null => {
  if (assetType.quoteMax && toBigInt(weiValue) > assetType.quoteMax) {
    return {
      errorMessage: `Max quote limit is ${formatWeiToHumanReadable(assetType.quoteMax, 2)} ${getUriId(assetType)}. For higher quotes, contact our team for custom pricing.`,
      status: AssetTypeBalanceValidity.MoreThanMax,
    };
  }
  return null;
};

export const getMinAmountValidationError = (
  weiValue: string,
  assetType: AssetType,
): AmountValidationResult | null => {
  if (toBigInt(weiValue) < assetType.min) {
    return {
      errorMessage: `A minimum of ${fromWei(assetType.min, assetType.precision)} ${getUriId(assetType)} needs to be sent.`,
      status: AssetTypeBalanceValidity.LessThanMin,
    };
  }
  return null;
};

export const validResult: AmountValidationResult = {
  errorMessage: '',
  status: AssetTypeBalanceValidity.Valid,
};

export const targetAmountValidationFunction: AmountValidationFunction = (
  amountToValidate,
  assetType,
  assetTypePairRule,
) => {
  const weiValue = toWei(amountToValidate, assetType.precision) || '0';
  return (
    getAssetPairRuleValidationError(weiValue, assetTypePairRule) ||
    getMaxQuoteValidationError(weiValue, assetType) ||
    validResult
  );
};

export const sourceAmountValidationFunction: AmountValidationFunction = (
  amountToValidate,
  assetType,
  assetTypePairRule,
) => {
  const weiValue = toWei(amountToValidate, assetType.precision) || '0';
  return (
    getMaxQuoteValidationError(weiValue, assetType) ||
    getUserBalanceLimitValidationError(weiValue, assetType) ||
    getAssetPairRuleValidationError(weiValue, assetTypePairRule) ||
    getMinAmountValidationError(weiValue, assetType) ||
    validResult
  );
};

export const withdrawnAmountValidationFunction: AmountValidationFunction = (
  amountToValidate,
  assetType,
) => {
  const weiValue = toWei(amountToValidate, assetType.precision) || '0';
  return (
    getUserBalanceLimitValidationError(weiValue, assetType) ||
    getMinAmountValidationError(weiValue, assetType) ||
    validResult
  );
};
