import React, { FC } from 'react';
import cx from 'classnames';

// eslint-disable-next-line no-restricted-syntax
import * as allColors from 'styles/_colors';
import Box from 'components/core/Box/Box';

import TextProps from './Text.types';
import styles from './Text.module.scss';

const Text: FC<TextProps> = ({
  align,
  children,
  className,
  overflow,
  variant = 'bodyCopyStandard',
  color,
  style,
  ...props
}) => {
  const combinedStyle = {
    ...style,
    ...(color && { color: allColors[color] }),
  };

  return (
    <Box
      className={cx(
        styles.root,
        styles[`variant--${variant}`],
        align && styles[`align--${align}`],
        overflow && styles[`overflow--${overflow}`],
        className,
      )}
      {...props}
      style={combinedStyle}
    >
      {children}
    </Box>
  );
};

export default Text;
