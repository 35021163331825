import { ReactNode } from 'react';

import { SetModalPropsProps } from 'components/core/Modal/ModalServiceWrapper/ModalServiceWrapper.types';
import ModalProps from 'components/core/Modal/Modal.types';

interface ServiceWrapperMethods {
  setIsBackdropVisible: (state: boolean) => void;
  setIsModalOpen: (state: ModalProps['isOpen']) => void;
  setModalContent: (content: ModalProps['children']) => void;
  setModalOnClosed: (callback?: () => void) => void;
  setModalProps: (props: SetModalPropsProps) => void;
}

interface ModalQueueItem {
  ModalContent: ReactNode;
  modalProps: ModalProps;
}

/**
 * A client service for global modal management.
 *
 * It acts as the single source of truth for all modals state in the app.
 * It provides method of managing backdrop and modal boxes in a way that
 * allows modal queueing, where one modal opens another in a smooth transition.
 *
 * @returns public client API for modal management
 */
const modalService = () => {
  /**
   * Stores currently open modal and, if required, next modal to be opened
   * after the first one closes.
   */
  const modalQueue: ModalQueueItem[] = [];

  let serviceWrapperMethods: ServiceWrapperMethods;

  /**
   * Links modalService instance with top-level ModalServiceWrapper component
   * in order to properly manage backdrop and active modal states through
   * its local state.
   */
  function bindServiceWrapperMethods(methods: ServiceWrapperMethods) {
    serviceWrapperMethods = methods;
  }

  /**
   * Toggles DOM's body tag 'blockScroll' class that prevents user from
   * being able to scroll the application page.
   */
  function setBodyScrollLock(isScrollLocked: boolean) {
    const scrollLockClassName = 'blockScroll';
    const { classList } = document.body;
    if (isScrollLocked) {
      classList.add(scrollLockClassName);
    } else {
      classList.remove(scrollLockClassName);
    }
  }

  /**
   * Opens next modal in modal queue.
   */
  function openNextModal() {
    const { ModalContent, modalProps } = modalQueue[0];
    serviceWrapperMethods.setModalProps(modalProps);
    serviceWrapperMethods.setModalContent(ModalContent);
    setTimeout(() => {
      serviceWrapperMethods.setIsModalOpen(true);
    }, 0);
  }

  /**
   * Closes currently open modal. If there is no new modal
   * to be immediately opened next, also removes backdrop.
   */
  function closeCurrentModal(modalOnClosed?: ModalProps['onClosed']) {
    serviceWrapperMethods.setModalOnClosed(() => () => {
      serviceWrapperMethods.setModalProps({});
      serviceWrapperMethods.setModalContent(null);
      serviceWrapperMethods.setModalOnClosed(undefined);
      if (modalOnClosed) {
        modalOnClosed();
      }
    });
    modalQueue.shift();
    serviceWrapperMethods.setIsModalOpen(false);
    if (!modalQueue.length) {
      serviceWrapperMethods.setIsBackdropVisible(false);
      setBodyScrollLock(false);
    }
  }

  /**
   * Opens new modal. If it's the first modal to be opened in a given queue,
   * also shows backdrop. If a modal is already open, closes it first and
   * then opens the new one.
   */
  function openModal(modalProps: ModalProps, ModalContent: ReactNode) {
    const isModalAlreadyOpen = modalQueue.length >= 1;
    modalQueue.push({
      ModalContent,
      modalProps,
    });
    if (isModalAlreadyOpen) {
      closeCurrentModal(openNextModal);
    } else {
      serviceWrapperMethods.setIsBackdropVisible(true);
      setBodyScrollLock(true);
      openNextModal();
    }
  }

  /**
   * Returns client API
   */
  return {
    bindServiceWrapperMethods,
    closeCurrentModal,
    openModal,
  };
};

/**
 * Automatically initializes and exports a singleton instance
 * of the service as it should act as the single source of truth.
 */
export default modalService();
