import { TransactionPurpose } from 'store/api/api.types';

export const transactionPurposeLabels = {
  [TransactionPurpose.InterCompanyTransfer]: 'Inter-Company Transfer',
  [TransactionPurpose.Investments]: 'Investments',
  [TransactionPurpose.Other]: 'Other',
  [TransactionPurpose.PaymentsForGoodsOrServices]: 'Payment for Goods or Services',
  [TransactionPurpose.Payroll]: 'Payroll',
};

export const transactionSelectOptions = [
  {
    label: transactionPurposeLabels[TransactionPurpose.PaymentsForGoodsOrServices],
    value: TransactionPurpose.PaymentsForGoodsOrServices,
  },
  {
    label: transactionPurposeLabels[TransactionPurpose.Payroll],
    value: TransactionPurpose.Payroll,
  },
  {
    label: transactionPurposeLabels[TransactionPurpose.InterCompanyTransfer],
    value: TransactionPurpose.InterCompanyTransfer,
  },
  {
    label: transactionPurposeLabels[TransactionPurpose.Investments],
    value: TransactionPurpose.Investments,
  },
  {
    label: transactionPurposeLabels[TransactionPurpose.Other],
    value: TransactionPurpose.Other,
  },
];
