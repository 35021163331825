import { curry } from 'lodash';

import { Policy } from 'store/api/api.types';

import { ActionName } from './CurrentUserContext.types';

const PORTAL_URI_PREFIX = 'conduit:portal';

export const isUserAllowedTo = curry(
  (policies: Policy[], organizationId: string, actionName: ActionName) => {
    const organizationPolicies = policies.filter(
      policy => policy.organizationId === organizationId,
    );
    return organizationPolicies.some(policy =>
      policy.permissions.some(permission =>
        permission.actions.includes(`${PORTAL_URI_PREFIX}:${actionName}`),
      ),
    );
  },
);
