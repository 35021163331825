import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import get from 'lodash/get';

import { ROOT_ROUTES } from 'router/constants';
import router from 'router/router';

const authMiddleware: Middleware = () => next => action => {
  const isCypressIntegrationTestRun =
    window.Cypress && window.Cypress.env('CYPRESS_INTEGRATION_TEST_RUN');

  if (
    !isCypressIntegrationTestRun &&
    isRejectedWithValue(action) &&
    get(action.payload, 'status') === 401
  ) {
    router.navigate(ROOT_ROUTES.LOGOUT.absolute);
  }

  if (isRejectedWithValue(action) && get(action.payload, 'status') === 503) {
    router.navigate(ROOT_ROUTES.UNDER_MAINTENANCE.absolute);
  }

  return next(action);
};

export default authMiddleware;
