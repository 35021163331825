import { useCallback, useState } from 'react';

import { Organization } from 'store/api/api.types';
import { TransactionExportFilter } from 'hooks/useTransactionReportExport.types';
import { apiUrl } from 'env';
import { dismiss, showError, showLoader, showSuccess } from 'services/notificationService';
import downloadFile from 'utils/downloadFile';
import getQueryStringFromParams from 'utils/getQueryStringFromParams';

export const useTransactionReportExport = (
  organizationId: Organization['id'],
  filter: TransactionExportFilter = {},
): {
  exportTransactionReport: () => void;
  isExporting: boolean;
} => {
  const [isExporting, setIsExporting] = useState(false);

  const exportTransactionReport = useCallback(async () => {
    setIsExporting(true);
    const loaderToastId = showLoader({
      message: 'This may take up to a minute. Please remain on this page.',
      title: 'Generating your CSV...',
    });

    try {
      const timestamp = Date.now();
      const baseUrl = `${apiUrl}/organizations/${organizationId}/platform/transactions/reports`;
      const filename = `transactions-report-${timestamp}.csv`;
      const filtersQueryString = getQueryStringFromParams({ filter });
      await downloadFile(baseUrl + filtersQueryString, filename);
      showSuccess('Your CSV has been sucessfully exported.');
    } catch {
      showError(
        'Something went wrong while generating your export. Please refresh the page and try again.',
      );
    } finally {
      setIsExporting(false);
      dismiss(loaderToastId);
    }
  }, [filter, organizationId]);

  return {
    exportTransactionReport,
    isExporting,
  };
};
