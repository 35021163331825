import cloneDeep from 'lodash/cloneDeep';

import { ACTIVE_COUNTRIES_OPTIONS } from 'constants/countries';
import { SelectFieldProps } from 'components/core/Form/SelectField/SelectField.types';

import {
  BRA_BUSINESS_IDENTIFICATION_TYPE_OPTION,
  BRA_PERSON_IDENTIFICATION_TYPE_OPTION,
  COL_BUSINESS_IDENTIFICATION_TYPES,
  COL_PERSON_IDENTIFICATION_TYPES,
  MEX_BUSINESS_IDENTIFICATION_TYPES,
  MEX_PERSON_IDENTIFICATION_TYPES,
} from './identityDetails.constants';
import { ComponentConfigWithId } from './types';
import { IdentityDetailsComponentId, IdentityDetailsComponents } from './identityDetails.types';
import { createSetIsVisibleHelper } from './helpers';
import SelectField from './components/SelectField';
import TextInput from './components/TextInput';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';
import { IdentityDetails } from '../FormIdentityDetails/FormIdentityDetails.types';

export const defaultIdentityDetailsComponentsConfig: IdentityDetailsComponents = {
  address: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'Address',
    },
  },
  businessName: {
    Component: TextInput,
    props: {
      label: 'Business Name',
    },
  },
  city: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'City',
    },
  },
  country: {
    Component: SelectField,
    isVisible: true,
    props: {
      label: 'Country',
      options: ACTIVE_COUNTRIES_OPTIONS,
    },
  },
  email: {
    Component: TextInput,
    props: {
      label: 'Email Address',
      type: 'email',
    },
  },
  firstName: {
    Component: TextInput,
    props: {
      label: 'First Name',
    },
  },
  identificationNumber: {
    Component: TextInput,
    props: {
      label: 'Document Number',
    },
  },
  identificationType: {
    Component: SelectField,
    props: {
      label: 'Document Type',
    },
  },
  lastName: {
    Component: TextInput,
    props: {
      label: 'Last Name',
    },
  },
  phone: {
    Component: TextInput,
    props: {
      label: 'Phone',
    },
  },
  postalCode: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'Postal Code',
    },
  },
  stateProvince: {
    Component: TextInput,
    isVisible: true,
    props: {
      label: 'State/Province',
    },
  },
};

export const identityDetailsComponentsOrder: IdentityDetailsComponentId[] = [
  IdentityDetailsComponentId.businessName,
  IdentityDetailsComponentId.firstName,
  IdentityDetailsComponentId.lastName,
  IdentityDetailsComponentId.country,
  IdentityDetailsComponentId.address,
  IdentityDetailsComponentId.city,
  IdentityDetailsComponentId.stateProvince,
  IdentityDetailsComponentId.postalCode,
  IdentityDetailsComponentId.identificationType,
  IdentityDetailsComponentId.identificationNumber,
  IdentityDetailsComponentId.email,
  IdentityDetailsComponentId.phone,
];

export const getIdentityDetailsFormConfig = (
  identityDetails: IdentityDetails,
  bankDetails: BankDetails,
  isBusiness: boolean,
): ComponentConfigWithId<IdentityDetailsComponentId>[] => {
  const components = cloneDeep(defaultIdentityDetailsComponentsConfig);
  const setIsVisible = createSetIsVisibleHelper(components);
  const { country: bankCountry, currency } = bankDetails;
  const { country: identityCountry, identificationType } = identityDetails;

  (components[IdentityDetailsComponentId.country].props as SelectFieldProps).selectedDefault =
    ACTIVE_COUNTRIES_OPTIONS.find(option => option.value === (identityCountry || bankCountry)) ||
    undefined;

  if (isBusiness) {
    setIsVisible(IdentityDetailsComponentId.businessName);
  } else {
    setIsVisible(IdentityDetailsComponentId.firstName);
    setIsVisible(IdentityDetailsComponentId.lastName);
  }

  if (bankCountry === 'COL' && currency === 'COP') {
    setIsVisible(IdentityDetailsComponentId.identificationType);
    setIsVisible(IdentityDetailsComponentId.identificationNumber);
    setIsVisible(IdentityDetailsComponentId.email);
    setIsVisible(IdentityDetailsComponentId.phone);

    components[IdentityDetailsComponentId.identificationType].props = {
      ...components[IdentityDetailsComponentId.identificationType].props,
      isHidden: isBusiness,
      options: isBusiness ? COL_BUSINESS_IDENTIFICATION_TYPES : COL_PERSON_IDENTIFICATION_TYPES,
      selectedDefault: isBusiness
        ? COL_BUSINESS_IDENTIFICATION_TYPES[0]
        : COL_PERSON_IDENTIFICATION_TYPES[0],
    };
  }

  if (bankCountry === 'MEX' && currency === 'MXN') {
    setIsVisible(IdentityDetailsComponentId.identificationType);
    setIsVisible(IdentityDetailsComponentId.identificationNumber);

    components[IdentityDetailsComponentId.identificationType].props = {
      ...components[IdentityDetailsComponentId.identificationType].props,
      isHidden: isBusiness,
      options: isBusiness ? MEX_BUSINESS_IDENTIFICATION_TYPES : MEX_PERSON_IDENTIFICATION_TYPES,
      selectedDefault: isBusiness
        ? MEX_BUSINESS_IDENTIFICATION_TYPES[0]
        : MEX_PERSON_IDENTIFICATION_TYPES[0],
    };
  }

  if (bankCountry === 'BRA' && currency === 'BRL') {
    setIsVisible(IdentityDetailsComponentId.identificationType);
    setIsVisible(IdentityDetailsComponentId.identificationNumber);
    setIsVisible(IdentityDetailsComponentId.email);

    components[IdentityDetailsComponentId.identificationType].props = {
      ...components[IdentityDetailsComponentId.identificationType].props,
      isHidden: true,
      options: isBusiness
        ? [BRA_BUSINESS_IDENTIFICATION_TYPE_OPTION]
        : [BRA_PERSON_IDENTIFICATION_TYPE_OPTION],
      selectedDefault: isBusiness
        ? BRA_BUSINESS_IDENTIFICATION_TYPE_OPTION
        : BRA_PERSON_IDENTIFICATION_TYPE_OPTION,
    };
  }

  components[IdentityDetailsComponentId.identificationNumber].props = {
    ...components[IdentityDetailsComponentId.identificationNumber].props,
    label: `${identificationType} Number`,
    placeholder: `Enter ${identificationType} Number`,
  };

  // return components in the order specified in identityDetailsComponentsOrder if they are visible
  return identityDetailsComponentsOrder
    .filter(componentId => components[componentId].isVisible)
    .map(componentId => {
      return {
        ...components[componentId],
        id: componentId,
      };
    });
};

export const getFilteredIdentityDetailFormValues = (
  identityDetails: IdentityDetails,
  bankDetails: BankDetails,
  isBusiness: boolean,
): IdentityDetails => {
  const config = getIdentityDetailsFormConfig(identityDetails, bankDetails, isBusiness);
  // Allow only keys that are present in the config:
  const allowedKeys = config.map(({ id }) => id);

  return Object.keys(identityDetails).reduce((acc, key) => {
    if (allowedKeys.includes(key as IdentityDetailsComponentId)) {
      return {
        ...acc,
        [key]: identityDetails[key],
      };
    }
    return acc;
  }, {} as IdentityDetails);
};
