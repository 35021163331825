import React, { FC } from 'react';

import UploadedFileField from 'components/core/Form/UploadedFileField/UploadedFileField';

import { UploadedFilesListProps } from './UploadedFilesList.types';
import styles from './UploadedFilesList.module.scss';

const UploadedFilesList: FC<UploadedFilesListProps> = ({ files, onRemoveFile }) => {
  const handleOnRemove = (index: number) => () => {
    onRemoveFile(index);
  };

  return (
    <>
      {files.map((file, index) => (
        <UploadedFileField
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className={styles.item}
          fileName={file.name}
          onRemoveFile={handleOnRemove(index)}
        />
      ))}
    </>
  );
};

export default UploadedFilesList;
