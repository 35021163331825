import { useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import IdentitiesTable from 'components/dedicated/organization/senders-recipients/IdentitiesTable/IdentitiesTable';
import ModalIdentityCreate from 'components/dedicated/organization/senders-recipients/ModalIdentityCreate/ModalIdentityCreate';
import PageHeader from 'components/core/PageHeader/PageHeader';
import modalService from 'services/modalService';

const IdentitiesView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const { isUserAllowedTo } = useCurrentUser();
  const canCreateIdentity = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateIdentity),
    [organizationId, isUserAllowedTo],
  );
  return (
    <ContentSection>
      <PageHeader title='Senders & Recipients'>
        {canCreateIdentity && (
          <Button
            label='Add New Sender / Recipient'
            onClick={() =>
              modalService.openModal(
                {
                  title: 'Sender/Recipient Details',
                },
                <ModalIdentityCreate organizationId={organizationId} />,
              )
            }
            size='small'
            type='submit'
            variant='primary'
          />
        )}
      </PageHeader>
      <IdentitiesTable organizationId={organizationId} />
    </ContentSection>
  );
};

export default IdentitiesView;
