import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import Text from 'components/core/Text/Text';

import PageHeaderProps from './PageHeader.types';
import styles from './PageHeader.module.scss';

const PageHeader: FC<PageHeaderProps> = ({
  className,
  children,
  title,
  variant = 'legacyTitle',
  ...props
}) => (
  <Box className={cx(styles.root, className)} Tag='header' {...props}>
    <Text variant={variant}>{title}</Text>
    {children && <div className={styles.headerItems}>{children}</div>}
  </Box>
);

export default PageHeader;
