import React, { FC } from 'react';
import cx from 'classnames';

import FormErrorMessageProps from './FormErrorMessage.module.types';
import styles from './FormErrorMessage.module.scss';

const FormErrorMessage: FC<FormErrorMessageProps> = ({ message, dataTestId, className }) =>
  message ? (
    <span className={cx(styles.root, className)} data-testid={dataTestId}>
      {message}
    </span>
  ) : null;

export default FormErrorMessage;
