import { Auth0Client } from '@auth0/auth0-spa-js';

import { auth0 } from 'env';

export default new Auth0Client({
  authorizationParams: {
    audience: auth0.audience,
    redirect_uri: window.location.origin,
  },
  cacheLocation: 'localstorage',
  clientId: auth0.clientId,
  domain: auth0.domain,
  useRefreshTokens: true,
});
