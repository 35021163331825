import { useParams } from 'react-router-dom';
import React, { ReactElement, useState } from 'react';

import { CreateTransactionContextProvider } from 'context/CreateTransactionContextProvider';
import { QuoteForm, QuotePreview } from 'components/dedicated/organization/send-money';
import { checkIfHasAgreedToColumnAgreement } from 'components/dedicated/organization/send-money/ColumnAgreement/ColumnAgreement.utils';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import ColumnAgreement from 'components/dedicated/organization/send-money/ColumnAgreement/ColumnAgreement';
import Spinner from 'components/core/Spinner/Spinner';

import { QuoteViewStep } from './QuoteView.types';
import styles from './QuoteView.module.scss';

const QuoteView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const { data: organization, refetch: refetchOrganization } = useGetOrganizationQuery({
    organizationId,
  });

  const [step, setStep] = useState<QuoteViewStep>(QuoteViewStep.FORM);

  const getStepClass = (nextStep: QuoteViewStep) => {
    return nextStep === step ? '' : styles.hide;
  };

  const changeStep = (nextStep: QuoteViewStep) => {
    if (nextStep === QuoteViewStep.PREVIEW) {
      // If is preview, check that the agreement has been accepted
      refetchOrganization().then(org => {
        if (checkIfHasAgreedToColumnAgreement(org.data)) {
          setStep(QuoteViewStep.PREVIEW);
        } else {
          setStep(QuoteViewStep.AGREEMENT);
        }
      });
    } else {
      setStep(nextStep);
    }
  };

  if (!organization) {
    return <Spinner />;
  }
  return (
    <CreateTransactionContextProvider>
      <div className={getStepClass(QuoteViewStep.FORM)}>
        <QuoteForm changeStep={changeStep} />
      </div>
      {organization && step === QuoteViewStep.AGREEMENT && (
        <ColumnAgreement changeStep={changeStep} organization={organization} />
      )}
      <div className={getStepClass(QuoteViewStep.PREVIEW)}>
        <QuotePreview changeStep={changeStep} organization={organization} />
      </div>
    </CreateTransactionContextProvider>
  );
};

export default QuoteView;
