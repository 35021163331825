import React, { FC, useMemo } from 'react';

import { BadgeProps } from 'components/core/Badge/Badge.types';
import { Identity } from 'store/api/api.types';
import Badge from 'components/core/Badge/Badge';
import Tooltip from 'components/core/Tooltip/Tooltip';

const IdentityStatus: FC<{ identity: Identity }> = ({ identity }) => {
  const { color, label, tooltip } = useMemo(() => {
    const status = identity.verificationStatus?.toLocaleLowerCase();
    let identityColor: BadgeProps['color'] = 'yellow';
    let identityTooltip =
      'This sender/recipient is currently undergoing compliance checks. Please refresh the page to verify the status.';
    let identityLabel = 'Pending';

    if (status === 'approved') {
      identityLabel = 'Active';
      identityColor = 'green';
      identityTooltip =
        'This sender/recipient has passed all compliance checks and is available for transactions.';
    }

    if (status === 'rejected') {
      identityLabel = 'Rejected';
      identityColor = 'red';
      identityTooltip =
        'This sender/recipient has not met the necessary compliance standards and cannot be used for transactions.';
    }

    return { color: identityColor, label: identityLabel, tooltip: identityTooltip };
  }, [identity]);

  return (
    <Tooltip label={tooltip}>
      <Badge color={color} label={label} />
    </Tooltip>
  );
};

export default IdentityStatus;
