/* eslint-disable jsx-a11y/control-has-associated-label */
import { flexRender, useReactTable } from '@tanstack/react-table';
import React, { ReactElement, useMemo } from 'react';
import cx from 'classnames';

import { chevronLeft, chevronRight } from 'components/core/Svg/icons';
import Button from 'components/core/Button/Button';
import Spinner from 'components/core/Spinner/Spinner';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

import TableProps from './Table.types';
import styles from './Table.module.scss';

function Table<T extends object>({
  className,
  dataTestId,
  options,
  handleOnNextPage,
  handleOnPreviousPage,
  isLoading,
  showPagination = true,
}: TableProps<T>): ReactElement {
  const table = useReactTable(options);

  const hasFooter = useMemo(() => {
    for (const footerGroup of table.getFooterGroups()) {
      for (const header of footerGroup.headers) {
        if (header.column.columnDef.footer) {
          return true;
        }
      }
    }
    return false;
  }, [table]);

  const hasPagination = useMemo(
    () => handleOnNextPage || handleOnPreviousPage,
    [handleOnNextPage, handleOnPreviousPage],
  );

  const resultsCount = useMemo(() => options.data.length, [options.data]);

  const PaginationFooter = (
    <>
      {!hasPagination && resultsCount > 0 && (
        <Text className={styles.resultsCount} variant='bodyCopySmall'>
          Viewing 1-{resultsCount} of {resultsCount} results
        </Text>
      )}
      {hasPagination && (
        <div className={styles.pagination}>
          <Button
            className={styles.paginationButton}
            isDisabled={!handleOnPreviousPage}
            label='Previous Page'
            LeadingIcon={<Svg img={chevronLeft} size={1.2} />}
            onClick={handleOnPreviousPage}
            size='small'
            variant='icon'
          />
          <Button
            className={styles.paginationButton}
            isDisabled={!handleOnNextPage}
            label='Next Page'
            onClick={handleOnNextPage}
            size='small'
            TrailingIcon={<Svg img={chevronRight} size={1.2} />}
            variant='icon'
          />
        </div>
      )}
    </>
  );
  return (
    <div className={cx(styles.root, className)} data-testid={dataTestId}>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={table.getAllLeafColumns().length}>
                  <Spinner marginBottom={4} marginTop={4} />
                </td>
              </tr>
            ) : (
              table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
          {hasFooter && (
            <tfoot>
              {table.getFooterGroups().map(footerGroup => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
      <div className={styles.footer}>{showPagination ? PaginationFooter : null}</div>
    </div>
  );
}

export default Table;
