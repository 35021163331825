import { getFilteredIdentityDetailFormValues } from './identityDetails';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';
import { IdentityDetails } from '../FormIdentityDetails/FormIdentityDetails.types';

export const areIdentityDetailsValid = (
  identityDetails: IdentityDetails,
  bankDetails: BankDetails,
  isBusiness: boolean,
): boolean =>
  Object.values(
    getFilteredIdentityDetailFormValues(identityDetails, bankDetails, isBusiness),
  ).every(value => !!value);

export const defaultIdentityDetails: IdentityDetails = {
  address: '',
  businessName: '',
  city: '',
  country: '',
  email: '',
  firstName: '',
  identificationNumber: '',
  identificationType: '',
  lastName: '',
  phone: '',
  postalCode: '',
  stateProvince: '',
};

export const getIdentityTitle = (
  identityDetails: Pick<IdentityDetails, 'businessName' | 'firstName' | 'lastName'>,
  isBusiness: boolean,
): string =>
  isBusiness
    ? identityDetails.businessName
    : `${identityDetails.firstName} ${identityDetails.lastName}`;
