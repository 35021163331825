import React, { FC } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

const CutOffMessage: FC<{ sourceAssetType?: AssetType; targetAssetType?: AssetType }> = ({
  sourceAssetType,
  targetAssetType,
}) => {
  const sourceMessage = sourceAssetType?.sourceCutOffMessage ? (
    <Noticebox showIcon={false} variant='note'>
      <Text align='center' variant='bodyCopySmall'>
        {sourceAssetType.sourceCutOffMessage}
      </Text>
    </Noticebox>
  ) : null;

  const targetMessage = targetAssetType?.targetCutOffMessage ? (
    <Noticebox showIcon={false} variant='note'>
      <Text align='center' variant='bodyCopySmall'>
        {targetAssetType.targetCutOffMessage}
      </Text>
    </Noticebox>
  ) : null;

  return !sourceMessage && !targetMessage ? null : (
    <>
      {sourceMessage}
      {targetMessage}
    </>
  );
};

export default CutOffMessage;
