import React, { FC, useCallback, useMemo } from 'react';

import { FormIdentityDetailsProps } from './FormIdentityDetails.types';

import { getIdentityDetailsFormConfig } from '../config/identityDetails';

const FormIdentityDetails: FC<FormIdentityDetailsProps> = ({
  bankDetails,
  setIdentityDetailsField,
  identityDetails,
  isBusiness,
  isProcessing,
}) => {
  const formComponents = useMemo(() => {
    return getIdentityDetailsFormConfig(identityDetails, bankDetails, isBusiness);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bankDetails.country,
    bankDetails.currency,
    isBusiness,
    identityDetails.identificationType,
    identityDetails.country,
  ]);

  const handleChange = useCallback(
    (id, value) => {
      setIdentityDetailsField(id, value);
    },
    [setIdentityDetailsField],
  );

  return (
    <div>
      <h4>{isBusiness ? 'Business' : 'Personal'} Details</h4>
      {formComponents.map(({ Component, id, props }) => (
        <Component
          key={id}
          isDisabled={isProcessing}
          onChange={({ target: { value } }) => handleChange(id, value)}
          value={identityDetails[id]}
          {...props}
        />
      ))}
    </div>
  );
};

export default FormIdentityDetails;
