import { format } from 'date-fns/format';
import { parseISO } from 'date-fns/parseISO';
import React, { FC } from 'react';

import { Invite } from 'store/api/api.types';
import Badge from 'components/core/Badge/Badge';

const InviteStatusDisplay: FC<{ invite: Invite }> = ({ invite: { expiresAt, status } }) => {
  if (status === 'expired') {
    return <Badge color='red' label='Expired' />;
  }
  if (status === 'accepted') {
    return <Badge color='green' label='Accepted' />;
  }
  return <span>{format(parseISO(expiresAt), 'y-MM-dd HH:mm')}</span>;
};

export default InviteStatusDisplay;
