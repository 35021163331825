import { SetStateAction, useCallback, useState } from 'react';

/**
 * Handles state changes operating purely on timeouts.
 *
 * @link Source: https://stackoverflow.com/a/64983274/1506624
 *
 * @example
 * ```ts
 * // sets loading to true for 1000ms, then back to false:
 * const [showLoading, setShowLoading] = useTimeoutState(false);
 * setShowLoading(true, { timeout: 1000});
 * ```
 */
const useTimeoutState = <T>(
  defaultState: T,
): [T, (action: SetStateAction<T>, opts?: { timeout: number }) => void] => {
  const [state, _setState] = useState<T>(defaultState);
  const [currentTimeoutId, setCurrentTimeoutId] = useState<NodeJS.Timeout | undefined>();

  const setState = useCallback(
    (action: SetStateAction<T>, opts?: { timeout: number }) => {
      if (currentTimeoutId != null) {
        clearTimeout(currentTimeoutId);
      }

      _setState(action);

      const id = setTimeout(() => _setState(defaultState), opts?.timeout);
      setCurrentTimeoutId(id);
    },
    [currentTimeoutId, defaultState],
  );
  return [state, setState];
};

export default useTimeoutState;
