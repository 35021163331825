import { ErrorCode, FileRejection } from 'react-dropzone';

import formatBytes from 'utils/formatBytes';

import { FileDropzoneProps } from './FileDropzone.types';
import { defaultOnDropRejectedErrors } from './FileDropzone.constants';

export const getOnDropRejectedErrors = (
  files: FileRejection[],
  onDropRejectedErrors: typeof defaultOnDropRejectedErrors,
  fileRequirements?: FileDropzoneProps['fileRequirements'],
): string[] => {
  const formErrors: string[] = [];
  const buildErrorMessage = (fileName, errorMessage) => {
    return files.length > 1 ? `${fileName}: ${errorMessage}` : errorMessage;
  };

  if (
    files.some(fileRejection =>
      fileRejection.errors.some(error => (error.code as ErrorCode) === 'too-many-files'),
    )
  ) {
    formErrors.push(onDropRejectedErrors[ErrorCode.TooManyFiles]);
  } else {
    files.forEach(fileRejection => {
      fileRejection.errors.forEach(error => {
        const { mimeTypes, maxSize } = fileRequirements || {};

        if (error.code === ErrorCode.FileInvalidType && mimeTypes) {
          const formattedacceptedFileTypes = mimeTypes.reduce((acc, mimeType, index) => {
            if (index === 0) {
              return mimeType;
            }
            return acc + (index === mimeTypes.length - 1 ? ' or ' : ', ') + mimeType;
          }, '');
          formErrors.push(
            buildErrorMessage(
              fileRejection.file.name,
              `Unsupported file type. Please upload a ${formattedacceptedFileTypes}.`,
            ),
          );
        } else if (error.code === ErrorCode.FileTooLarge && maxSize) {
          formErrors.push(
            buildErrorMessage(
              fileRejection.file.name,
              `Document exceeds ${formatBytes(maxSize)} limit. Please reduce the size and try again.`,
            ),
          );
        } else {
          formErrors.push(buildErrorMessage(fileRejection.file.name, error.message));
        }
      });
    });
  }
  return formErrors;
};
