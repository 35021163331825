import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';

import { BankAccountType } from './bankDetails.types';

export const CHECKING_BANK_ACCOUNT_OPTION: SelectFieldOption = {
  label: 'Checking',
  value: BankAccountType.CHECKING,
};

export const BANK_ACCOUNT_TYPE_OPTIONS: SelectFieldOption[] = [
  CHECKING_BANK_ACCOUNT_OPTION,
  { label: 'Savings', value: BankAccountType.SAVINGS },
];
