import React, { FC } from 'react';
import cx from 'classnames';

import Text from 'components/core/Text/Text';

import ModalHeaderProps from './ModalHeader.types';
import styles from './ModalHeader.module.scss';

const ModalHeader: FC<ModalHeaderProps> = ({ className, dataTestId, title }) => (
  <header className={cx(styles.root, className)} data-testid={dataTestId}>
    <Text Tag='h2' variant='sectionHeaderLarge'>
      {title}
    </Text>
  </header>
);

export default ModalHeader;
