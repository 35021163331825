/* eslint-disable react/no-unstable-nested-components */
import { createColumnHelper, getCoreRowModel } from '@tanstack/react-table';
import React, { FC, useMemo } from 'react';

import { Organization, OrganizationMember, PaginationMode } from 'store/api/api.types';
import { uppercaseFirstChar } from 'utils/uppercaseFirstChar';
import { useGetMembersQuery, useGetOrganizationRolesQuery } from 'store/api/rootApi';
import Spinner from 'components/core/Spinner/Spinner';
import Table from 'components/core/Table/Table';
import Text from 'components/core/Text/Text';
import usePaginationLinks from 'hooks/usePaginationLinks';
import usePaginationQuery from 'hooks/usePaginationQuery';

import MemberActionsButton from './MemberActionsButton/MemberActionsButton';

const columnHelper = createColumnHelper<OrganizationMember>();

const MembersTable: FC<{ organizationId: Organization['id'] }> = ({ organizationId }) => {
  const { pageBefore, pageAfter } = usePaginationQuery();
  const { isLoading: isLoadingRoles } = useGetOrganizationRolesQuery({ organizationId });
  const {
    data: membersData,
    isLoading: isLoadingMembers,
    isFetching: isFetchingMembers,
  } = useGetMembersQuery({
    organizationId,
    page: {
      after: pageAfter || undefined,
      before: pageBefore || undefined,
      mode: PaginationMode.Cursor,
    },
  });
  const { handleOnNextPage, handleOnPreviousPage } = usePaginationLinks(
    PaginationMode.Cursor,
    membersData?.links,
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {
        cell: info => <Text variant='legacyBodySmallEmphasized'>{info.getValue()}</Text>,
        header: 'Email',
      }),
      columnHelper.accessor('roles', {
        cell: info => {
          const roles = info.getValue();
          return roles.map(role => uppercaseFirstChar(role.name)).join(', ');
        },
        header: 'Role',
      }),
      columnHelper.display({
        cell: props => (
          <MemberActionsButton member={props.row.original} organizationId={organizationId} />
        ),
        id: 'actions',
      }),
    ],
    [organizationId],
  );

  if (isLoadingMembers || isLoadingRoles) {
    return <Spinner />;
  }

  return (
    <div>
      <Table
        handleOnNextPage={handleOnNextPage}
        handleOnPreviousPage={handleOnPreviousPage}
        isLoading={isFetchingMembers}
        options={{
          columns,
          data: membersData?.data || [],
          getCoreRowModel: getCoreRowModel(),
        }}
      />
    </div>
  );
};

export default MembersTable;
