import React, { FC } from 'react';
import cx from 'classnames';

import { DefaultComponentWithChildren } from 'components/components.types';

import styles from './FrameBoxFooter.module.scss';

const FrameBoxFooter: FC<DefaultComponentWithChildren> = ({ children, className }) => (
  <div className={cx(styles.root, className)}>{children}</div>
);

export default FrameBoxFooter;
