import React, { FC } from 'react';
import cx from 'classnames';

import { DefaultComponentWithChildren } from 'components/components.types';

import styles from './ContentSection.module.scss';

const ContentSection: FC<DefaultComponentWithChildren> = ({ children, className, dataTestId }) => (
  <section className={cx(styles.root, className)} data-testid={dataTestId}>
    {children}
  </section>
);

export default ContentSection;
