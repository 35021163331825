import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { RootState } from 'store/store';
import { apiUrl } from 'env';
import { selectToken } from 'store/auth/auth.slice';

export const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = selectToken(getState() as RootState);
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});
