// eslint-disable-next-line no-restricted-syntax
import * as SentryClient from '@sentry/react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import React from 'react';

import { DEBUG_CONTEXTS, DEBUG_FLAGS } from 'constants/debug';
import { commitHash, environment, googleTagManager, sentry } from 'env';
import debugService from 'services/debugService';
import gtmService from 'services/gtmService';
import router from 'router/router';
import store from 'store/store';

import './index.scss';

debugService.debug.success('App', `init - ${environment} ${commitHash || ''}`);

if (sentry.dsn) {
  debugService.debug.success('App', 'Sentry init', sentry);
  SentryClient.init({
    dsn: sentry.dsn,
    environment,
    integrations: [
      SentryClient.browserTracingIntegration(),
      SentryClient.replayIntegration({
        // recordings from production must have all sensitive data masked
        blockAllMedia: environment === 'production',
        maskAllText: environment === 'production',
      }),
    ],
    release: commitHash,
    replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate,
    replaysSessionSampleRate: sentry.replaysSessionSampleRate,
    tracePropagationTargets: [
      'localhost',
      // match all potential CP API routes
      /^https:\/\/api\.([a-z0-9-]+[.])*portal\.conduit\.financial\//,
    ],
    tracesSampleRate: sentry.tracesSampleRate,
  });
}

if (googleTagManager.id) {
  debugService.debug.info('GtmService', 'GTM init', googleTagManager);
  gtmService.mount(googleTagManager.id, [], {
    /* eslint-disable @typescript-eslint/naming-convention */
    gtm_auth: googleTagManager.auth,
    gtm_cookies_win: googleTagManager.cookiesWin,
    gtm_preview: googleTagManager.preview,
    /* eslint-enable @typescript-eslint/naming-convention */
  });
}

debugService.setupLocalStorageDebugKeys('context', [...DEBUG_CONTEXTS]);
debugService.setupLocalStorageDebugKeys('flag', [...DEBUG_FLAGS]);

const root = createRoot(document.getElementById('root')!);
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>,
);
