import { CurrentUser } from 'store/api/api.types';

export interface CurrentUserContextType {
  isUserAllowedTo: (organizationId: string, action: ActionName) => boolean;
  user?: CurrentUser;
}

export enum ActionName {
  ChangeMemberRoles = 'ChangeMemberRoles',
  CreateBankAccount = 'CreateBankAccount',
  CreateIdentity = 'CreateIdentity',
  CreateInvite = 'CreateInvite',
  CreateKey = 'CreateKey',
  CreateQuote = 'CreateQuote',
  CreateTransaction = 'CreateTransaction',
  DeleteBankAccount = 'DeleteBankAccount',
  DeleteIdentity = 'DeleteIdentity',
  DeleteInvite = 'DeleteInvite',
  DeleteMember = 'DeleteMember',
  ReadIdentity = 'ReadIdentity',
  ReadInvite = 'ReadInvite',
  ReadKey = 'ReadKey',
  ReadMember = 'ReadMember',
  ReadTransaction = 'ReadTransaction',
  ResendInvite = 'ResendInvite',
}
