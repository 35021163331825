import curry from 'lodash/curry';

import { AssetTypeUri } from 'constants/assetTypes.types';
import { TradingPair } from 'store/api/api.types';

import { AssetTradeablePairs, PairedAsset } from './AssetsSelector.types';

export const sortAssetUrisByTicker = (
  a: AssetTypeUri | PairedAsset,
  b: AssetTypeUri | PairedAsset,
): number => {
  const tickerA = (typeof a === 'string' ? a : a.uri).split(':')[2];
  const tickerB = (typeof b === 'string' ? b : b.uri).split(':')[2];
  return tickerA.localeCompare(tickerB);
};

export const getTradeablePairs = (tradingPairs: TradingPair[]): AssetTradeablePairs => {
  const tradeablePairs: AssetTradeablePairs = {};
  tradingPairs.forEach(({ source, enabled, target }) => {
    tradeablePairs[source] = [
      ...(tradeablePairs[source] || []),
      {
        enabled,
        uri: target,
      },
    ];
  });
  Object.keys(tradeablePairs).forEach(source => {
    tradeablePairs[source].sort(sortAssetUrisByTicker);
  });
  return tradeablePairs;
};

export const hasTradeablePair = curry(
  (tradeablePairs: AssetTradeablePairs, source: AssetTypeUri, target: AssetTypeUri): boolean => {
    return !!(tradeablePairs[source] || []).find(pair => pair.uri === target && pair.enabled);
  },
);

export const hasAnyTradeablePairsEnabled = curry(
  (tradeablePairs: AssetTradeablePairs, source: AssetTypeUri): boolean => {
    return (tradeablePairs[source] || []).some(pair => pair.enabled) || false;
  },
);
