/* eslint-disable default-param-last */
import { createApi } from '@reduxjs/toolkit/query/react';

import getQueryStringFromParams from 'utils/getQueryStringFromParams';

import {
  ApiRequestParams,
  CurrentUser,
  Invite,
  Organization,
  OrganizationInvitesResponse,
  OrganizationMember,
  OrganizationMembersResponse,
  OrganizationRole,
  Organizations,
  PortalStatus,
} from './api.types';
import { baseQuery } from './api.config';

export const rootApi = createApi({
  baseQuery,
  endpoints: builder => ({
    acceptAgreement: builder.mutation<
      Organization,
      { agreementId: string; organizationId: Organization['id'] }
    >({
      invalidatesTags: [{ id: 'ORGANIZATIONS', type: 'Organization' }],
      query: ({ agreementId, organizationId }) => ({
        body: { agreementId },
        method: 'POST',
        url: `organizations/${organizationId}/agreements`,
      }),
    }),
    acceptInvite: builder.mutation<Organization, { inviteHash: string }>({
      invalidatesTags: [{ type: 'CurrentUser' }],
      query: ({ inviteHash }) => ({
        method: 'POST',
        url: `invites/${inviteHash}/accept`,
      }),
    }),
    changeMemberRole: builder.mutation<
      OrganizationMember['roles'],
      {
        memberId: OrganizationMember['id'];
        organizationId: Organization['id'];
        roleIds: OrganizationRole['id'][];
      }
    >({
      invalidatesTags: (_result, _error, { memberId }) => [{ id: memberId, type: 'Member' }],
      query: ({ organizationId, memberId, roleIds }) => ({
        body: { roleIds },
        method: 'PATCH',
        url: `organizations/${organizationId}/members/${memberId}/roles`,
      }),
    }),
    createInvite: builder.mutation<
      Organization,
      { email; organizationId: Organization['id']; roleId?: OrganizationRole['id'] }
    >({
      invalidatesTags: (_result, _error, { organizationId }) => [
        { id: `INVITES-${organizationId}`, type: 'Invite' },
      ],
      query: ({ organizationId, email, roleId }) => ({
        body: { email, roleId },
        method: 'POST',
        url: `organizations/${organizationId}/invites`,
      }),
    }),
    createOrganization: builder.mutation<Organization, { name: Organization['name'] }>({
      invalidatesTags: [{ id: 'ORGANIZATIONS', type: 'Organization' }, { type: 'CurrentUser' }],
      query: body => ({
        body,
        method: 'POST',
        url: 'organizations',
      }),
    }),
    deleteInvite: builder.mutation<
      Organization,
      { inviteId: Invite['id']; organizationId: Organization['id'] }
    >({
      invalidatesTags: (_result, _error, { inviteId }) => [{ id: inviteId, type: 'Invite' }],
      query: ({ organizationId, inviteId }) => ({
        method: 'DELETE',
        url: `organizations/${organizationId}/invites/${inviteId}`,
      }),
    }),
    deleteMember: builder.mutation<
      Organization,
      { memberId: Invite['id']; organizationId: Organization['id'] }
    >({
      invalidatesTags: (_result, _error, { memberId }) => [{ id: memberId, type: 'Member' }],
      query: ({ organizationId, memberId }) => ({
        method: 'DELETE',
        url: `organizations/${organizationId}/members/${memberId}`,
      }),
    }),
    getCurrentUser: builder.query<CurrentUser, void>({
      providesTags: (_result, _error) => [{ type: 'CurrentUser' }],
      query: () => 'users/me',
    }),
    getInvites: builder.query<
      OrganizationInvitesResponse,
      { organizationId: Organization['id']; page: ApiRequestParams['page'] }
    >({
      providesTags: (result, _error, { organizationId }) => [
        ...(result?.data || []).map(({ id }) => ({ id, type: 'Invite' }) as const),
        { id: 'INVITES', type: 'Invite' as const },
        { id: `INVITES-${organizationId}`, type: 'Invite' },
      ],
      query: ({ organizationId, page }) => {
        const queryStringParams = getQueryStringFromParams({
          page,
        });
        return `organizations/${organizationId}/invites${queryStringParams}`;
      },
    }),
    getMembers: builder.query<
      OrganizationMembersResponse,
      { organizationId: Organization['id']; page: ApiRequestParams['page'] }
    >({
      providesTags: result => [
        ...(result?.data || []).map(({ id }) => ({ id, type: 'Member' }) as const),
        { id: 'MEMBERS', type: 'Member' as const },
      ],
      query: ({ organizationId, page }) => {
        const queryStringParams = getQueryStringFromParams({
          page,
        });
        return `organizations/${organizationId}/members${queryStringParams}`;
      },
    }),
    getOrganization: builder.query<Organization, { organizationId: Organization['id'] }>({
      providesTags: (_result, _error, { organizationId }) => [
        { id: organizationId, type: 'Organization' },
      ],
      query: ({ organizationId }) => `organizations/${organizationId}`,
    }),
    getOrganizationRoles: builder.query<OrganizationRole[], { organizationId: Organization['id'] }>(
      {
        providesTags: (result = []) => [
          ...result.map(({ id }) => ({ id, type: 'Role' }) as const),
          { id: 'ROLES', type: 'Role' as const },
        ],
        query: ({ organizationId }) => `organizations/${organizationId}/roles`,
      },
    ),
    getOrganizations: builder.query<Organizations, void>({
      providesTags: (result = []) => [
        ...result.map(({ id }) => ({ id, type: 'Organization' }) as const),
        { id: 'ORGANIZATIONS', type: 'Organization' as const },
      ],
      query: () => `organizations`,
    }),
    getPortalStatus: builder.query<PortalStatus, void>({
      providesTags: (_result, _error) => [{ type: 'PortalStatus' }],
      query: () => 'status',
    }),
    resendInvite: builder.mutation<
      Organization,
      { inviteId: Invite['id']; organizationId: Organization['id'] }
    >({
      invalidatesTags: (_result, _error, { inviteId }) => [{ id: inviteId, type: 'Invite' }],
      query: ({ organizationId, inviteId }) => ({
        method: 'POST',
        url: `organizations/${organizationId}/invites/${inviteId}/resend`,
      }),
    }),
  }),
  reducerPath: 'rootApi',
  tagTypes: ['CurrentUser', 'Invite', 'Member', 'Organization', 'Role', 'PortalStatus'],
});

export const {
  useAcceptAgreementMutation,
  useAcceptInviteMutation,
  useChangeMemberRoleMutation,
  useCreateInviteMutation,
  useCreateOrganizationMutation,
  useDeleteInviteMutation,
  useDeleteMemberMutation,
  useGetCurrentUserQuery,
  useGetInvitesQuery,
  useGetMembersQuery,
  useGetOrganizationQuery,
  useGetOrganizationRolesQuery,
  useGetOrganizationsQuery,
  useGetPortalStatusQuery,
  useResendInviteMutation,
} = rootApi;
