import { useParams } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { useGetOrganizationQuery } from 'store/api/rootApi';
import ContentSection from 'components/core/ContentSection/ContentSection';
import PageHeader from 'components/core/PageHeader/PageHeader';

const SettingsView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const { data: organization } = useGetOrganizationQuery({ organizationId });

  return (
    <ContentSection>
      <PageHeader title={organization?.name} />
      Organization settings page - work in progress.
    </ContentSection>
  );
};

export default SettingsView;
