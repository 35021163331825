/* eslint-disable no-console */

import React, { FC } from 'react';
import isArray from 'lodash/isArray';
import validateColor from 'validate-color';

import { SvgAttrs, SvgProps, SvgStyles } from './Svg.types';

const Svg: FC<SvgProps> = ({
  alt,
  className,
  color,
  dataTestId = '',
  img,
  onClick = () => {},
  size = 'auto',
  sizeUnit = 'rem',
  title,
}) => {
  const parsedSize = isArray(size) ? size : [size, size];
  const width = parsedSize[0] === 'auto' ? parsedSize[0] : `${parsedSize[0]}${sizeUnit}`;
  const height = parsedSize[1] === 'auto' ? parsedSize[1] : `${parsedSize[1]}${sizeUnit}`;

  const svgStyles: SvgStyles = {
    height,
    maxHeight: height,
    maxWidth: width,
    minHeight: height,
    minWidth: width,
    width,
  };

  // Check unobvious requirements & rules:
  if (color && color !== 'currentColor' && img.markup) {
    console.warn('Svg', 'Color prop have no effect on images using normal markup instead of path.');
  }
  if (color && color !== 'currentColor' && !validateColor(color)) {
    console.warn('Svg', `Invalid color value "${color}"`);
  }
  if (img.markup && img.markup.toLowerCase().includes('<svg')) {
    console.warn('Svg', 'Markup must not include <svg /> tag');
  }
  if (img.markup && img.path) {
    console.warn('Svg', 'Choose either markup or path prop, not both');
  }
  if (img.path && img.path.includes('<')) {
    console.warn('Svg', 'Path must contain d value only');
  }

  const appliedColor = color || img.defaultColor || 'currentColor';
  const viewBox = img.viewBox || '0 0 24 24';
  const svgAttrs: SvgAttrs = {
    alt,
    className,
    onClick,
    style: svgStyles,
    title,
    viewBox,
  };

  if (img.markup) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    svgAttrs.dangerouslySetInnerHTML = { __html: img.markup };
  } else {
    svgAttrs.children = <path d={img.path} fill={appliedColor} />;
  }

  return <svg {...svgAttrs} data-testid={dataTestId} />;
};

export default Svg;
