import React, { FC } from 'react';

import Text from 'components/core/Text/Text';

import { LoaderTextProps } from './LoaderText.types';
import styles from './LoaderText.module.scss';

const LoaderText: FC<LoaderTextProps> = ({
  label,
  variant = 'bodyCopySmall',
  isLoading = true,
  ...props
}) => (
  <Text variant={variant} {...props}>
    {label}
    {isLoading && <span className={styles.loader} />}
  </Text>
);

export default LoaderText;
