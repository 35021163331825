import React, { FC } from 'react';
import cx from 'classnames';

import { DotProps } from './Dot.types';
import styles from './Dot.module.scss';

const Dot: FC<DotProps> = ({ className, color, dataTestId, isAnimated, size = 'normal' }) => {
  return (
    <div
      className={cx(
        styles.root,
        styles[`size--${size}`],
        styles[`color--${color}`],
        isAnimated && styles.isAnimated,
        className,
      )}
      data-testid={dataTestId}
    />
  );
};

export default Dot;
