import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';

import {
  ErrorMessagesMap,
  ErrorResponse,
  LocalFormError,
  LocalFormErrors,
  ResponseError,
} from './api.types';

export const isFetchBaseQueryErrorType = (
  error?: FetchBaseQueryError | SerializedError,
): error is FetchBaseQueryError => !!error && 'status' in error;

export const isGenericErrorType = (error?: FetchBaseQueryError | SerializedError): boolean =>
  isFetchBaseQueryErrorType(error) && !(error as ErrorResponse).data?.errors;

export const getFieldResponseError = (
  fieldName: string,
  responseError: FetchBaseQueryError | SerializedError | undefined,
): ResponseError | undefined => {
  if (isFetchBaseQueryErrorType(responseError) && !isGenericErrorType(responseError)) {
    const {
      data: { errors },
    } = responseError as ErrorResponse;
    const fieldErrors = errors.filter(
      error => error.source?.pointer === `/data/attributes/${fieldName}`,
    );
    // fields can only display a single error message at a time
    return fieldErrors[0];
  }
  return undefined;
};

export const getFieldResponseErrorMessage = (
  fieldName: string,
  responseError: FetchBaseQueryError | SerializedError | undefined,
): string | undefined => {
  const fieldResponseError = getFieldResponseError(fieldName, responseError);
  return fieldResponseError ? fieldResponseError.title : undefined;
};

export const mapFieldErrorMessage = (
  fieldName: string,
  responseError: FetchBaseQueryError | SerializedError | undefined,
  errorMessagesMap: ErrorMessagesMap,
): string | undefined => {
  const fieldResponseError = getFieldResponseError(fieldName, responseError);
  if (fieldResponseError) {
    return fieldResponseError.code ? errorMessagesMap[fieldResponseError.code] : undefined;
  }
  return undefined;
};

export const getLocalFormErrorMessage = (
  fieldName: string,
  responseError: undefined | LocalFormError | LocalFormErrors,
): string | undefined => {
  if (responseError === undefined) {
    return undefined;
  }
  if (Array.isArray(responseError)) {
    const fieldError = responseError.find(error => error.fieldName === fieldName);
    return fieldError ? fieldError.message : undefined;
  }
  return responseError.fieldName === fieldName ? responseError.message : undefined;
};
