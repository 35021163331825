import React, { FC } from 'react';

import Box from 'components/core/Box/Box';

import { LoaderBarProps } from './LoaderBar.types';
import styles from './LoaderBar.module.scss';

const LoaderBar: FC<LoaderBarProps> = props => {
  return (
    <Box {...props}>
      <div className={styles.loaderBar} />
    </Box>
  );
};

export default LoaderBar;
