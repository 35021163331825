/* eslint-disable no-console */
import { DEBUG_CONTEXTS, DEBUG_FLAGS } from 'constants/debug';

/**
 * Service for handling console logging & debugging.
 *
 * It operates using localStorage keys, split into two types:
 * - context keys - for enabling console debug logs for given context
 * - flag keys - for enabling certain behaviors for debug purposes
 *
 * Using console logging:
 * ----------------------
 * To ALWAYS log output to console, use debugService.log[logType] methods, eg.:
 * `debugService.log.error('SomeClass', 'There was an error processing your request', error);`
 *
 * To log output to console ONLY IF context debug key is present in localStorage
 * and is set to true, use debugService.debug[logType] methods, eg.:
 * `debugService.debug.success('TestService', 'Service activated!');`
 * This will output given type of log only when `TestService` context debug key in localStorage
 * is set to true.
 *
 * Please note:
 * ------------
 * localStorage debug keys uses common prefix for ease of access and are all autogenerated
 * in development environment. They can also be autogenerated on staging/production environment
 * by manually setting a `debug__flag--generateDebugKeys` localStorage key to `true`.
 */
const debugService = () => {
  type DebugKeyType = 'context' | 'flag';
  type DebugContextType = (typeof DEBUG_CONTEXTS)[number];
  type DebugFlagType = (typeof DEBUG_FLAGS)[number];

  /**
   * Gets localStorage debug key name
   *
   * @param type key type, eg. "context", "flag"
   * @param name key name, eg. component name
   *
   * @returns full localStorage key name
   */
  const getKeyName = (type: DebugKeyType, name: string) => `debug__${type}--${name}`;

  /**
   * Gets localStorage context key name
   *
   * @param context context name
   *
   * @returns full localStorage key name
   */
  const getContextKeyName = (context: DebugContextType) => getKeyName('context', context);

  /**
   * Gets localStorage context key value
   *
   * @param context context name
   *
   * @returns localStorage key value
   */
  const getContextKeyValue = (context: DebugContextType) =>
    localStorage.getItem(getContextKeyName(context));

  /**
   * Gets localStorage flag key name
   *
   * @param flag flag name
   *
   * @returns full localStorage key name
   */
  const getFlagKeyName = (flag: DebugFlagType) => getKeyName('flag', flag);

  /**
   * Gets localStorage flag key value
   *
   * @param flag flag name
   *
   * @returns localStorage key value
   */
  const getFlagKeyValue = (flag: DebugFlagType) => localStorage.getItem(getFlagKeyName(flag));

  /**
   * Adds all keys of a given type to localStorage if they are not already present there.
   *
   * @param type type of keys to be added, eg. "context", "flag"
   * @param keyNames an array of key names
   */
  const setupLocalStorageDebugKeys = (
    type: DebugKeyType,
    keyNames: DebugContextType[] | DebugFlagType[],
  ) => {
    if (process.env.NODE_ENV === 'development' || getFlagKeyValue('generateDebugKeys') === 'true') {
      keyNames.forEach(rawKeyName => {
        const keyName = getKeyName(type, rawKeyName);
        if (!localStorage.getItem(keyName)) {
          localStorage.setItem(keyName, '');
        }
      });
    }
  };

  /**
   * Logs to console.
   * Provides a unified interface for all methods:
   *
   * @param type type of message we want to display
   * @param context what does this log relates to, eg. class or component name
   * @param args arguments, passed directly to console.log function
   */
  const consoleLog = (
    type: 'info' | 'success' | 'warn' | 'error',
    context: string,
    ...args: any[]
  ) => {
    if (type === 'success') {
      console.log(`%c### ${context}:`, 'color: LimeGreen', ...args);
    } else if (type === 'info') {
      console.log(`%c### ${context}:`, 'color: DeepSkyBlue', ...args);
    } else {
      console[type](`### ${context}:`, ...args);
    }
  };

  /**
   * Unified console logging methods
   *
   * @param context what does this log relates to, eg. class or component name
   * @param args any number of parameters to be passed to console method
   */
  const info = (context: string, ...args: any[]) => consoleLog('info', context, ...args);
  const success = (context: string, ...args: any[]) => consoleLog('success', context, ...args);
  const warning = (context: string, ...args: any[]) => consoleLog('warn', context, ...args);
  const error = (context: string, ...args: any[]) => consoleLog('error', context, ...args);

  /**
   * Checks localStorage for debug key matching context and runs the logging method
   * if debug key is set to `true`.
   *
   * @param consoleCall one of the unified console logging methods
   */
  const filterLogByContextKey = (consoleCall: Function) => {
    return (context: DebugContextType, ...args: any[]) => {
      const keyName = getContextKeyName(context);
      if (localStorage.getItem(keyName) === 'true') {
        consoleCall(context, ...args);
      }
    };
  };

  return {
    debug: {
      error: filterLogByContextKey(error),
      info: filterLogByContextKey(info),
      success: filterLogByContextKey(success),
      warning: filterLogByContextKey(warning),
    },
    getContextKeyName,
    getContextKeyValue,
    getFlagKeyName,
    getFlagKeyValue,
    log: {
      error,
      info,
      success,
      warning,
    },
    setupLocalStorageDebugKeys,
  };
};

export default debugService();
