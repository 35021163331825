import React, { FC } from 'react';

import { Organization } from 'store/api/api.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError } from 'services/notificationService';
import { useCreateKeyMutation } from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import ModalCreateKeySuccessful, {
  modalCreateKeySuccessfulTitle,
} from 'components/dedicated/ModalCreateKeySuccessful/ModalCreateKeySuccessful';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalCreateKeyTitle = 'Create API Key';

const ModalCreateKey: FC<{
  organizationId: Organization['id'];
}> = ({ organizationId }) => {
  const [createKey, { isLoading: isCreatingKey }] = useCreateKeyMutation();

  const handleCreateKey = async e => {
    e.preventDefault();
    const result = await createKey({ organizationId });
    if (!('error' in result)) {
      const { data } = result;
      modalService.openModal(
        { title: modalCreateKeySuccessfulTitle },
        <ModalCreateKeySuccessful createdKey={data} />,
      );
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to create the API Key. Please try again later.');
    }
  };

  return (
    <form onSubmit={handleCreateKey}>
      <ModalContent variant='noBottomPadding'>
        <Text>Please confirm creation of a new API Key.</Text>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          isDisabled={isCreatingKey}
          label='Cancel'
          onClick={() => {
            modalService.closeCurrentModal();
          }}
          variant='textCta'
        />
        <Button isLoading={isCreatingKey} label='Create Key' type='submit' variant='primary' />
      </ModalContent>
    </form>
  );
};

export default ModalCreateKey;
