import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import auth0Client from 'services/auth0Client';
import type { RootState } from 'store/store';

import { Auth0User, AuthState } from './auth.types';

export const initAuth = createAsyncThunk<{
  token: AuthState['token'];
  user: Auth0User;
}>('auth/initAuth', async () => {
  await auth0Client.checkSession();
  const query = window.location.search;
  if (query.includes('code=') && query.includes('state=')) {
    await auth0Client.handleRedirectCallback();
  }
  const token = await auth0Client.getTokenSilently();
  const user = (await auth0Client.getUser()) as Auth0User;
  return {
    token,
    user,
  };
});

const initialState: AuthState = {
  isAuthenticated: false,
  processing: 'idle',
  token: null,
  user: null,
};

export const authSlice = createSlice({
  extraReducers: builder => {
    builder.addCase(initAuth.pending, state => {
      state.isAuthenticated = false;
      state.processing = 'pending';
      state.user = null;
    });
    builder.addCase(initAuth.fulfilled, (state, { payload }) => {
      state.isAuthenticated = true;
      state.processing = 'succeeded';
      state.token = payload.token;
      state.user = payload.user;
    });
    builder.addCase(initAuth.rejected, state => {
      state.isAuthenticated = false;
      state.processing = 'failed';
      state.token = null;
      state.user = null;
    });
  },
  initialState,
  name: 'auth',
  reducers: {},
});

export const selectAuth = (state: RootState): AuthState => state.auth;
export const selectToken = (state: RootState): AuthState['token'] => state.auth.token;

export default authSlice.reducer;
