import { toWei } from 'web3-utils';
import { useParams } from 'react-router-dom';
import React, { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import { Quote, TransactionPurpose } from 'store/api/api.types';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { convertFromBaseUnit, getUriIdAndChainId, isFiat } from 'utils/format';
import {
  getAssetPairRuleValidationError,
  getAssetTypePairRule,
  getMaxQuoteValidationError,
  getMinAmountValidationError,
} from 'components/core/Form/AssetTypeField/AssetTypeField.utils';
import { getLiquidityLimitStatus } from 'components/dedicated/organization/send-money/LiquidityLimitMessage/LiquidityLimitMessage.utils';
import { isCurrentTimeInRange } from 'utils/isCurrentTimeInRange';
import { showError } from 'services/notificationService';
import { useCreateQuoteMutation, useGetLiquidityLimitsQuery } from 'store/api/platformApi';

import { CreateTransactionContextType } from './CreateTransactionContext.types';
import CreateTransactionContext from './CreateTransactionContext';

const REFETCH_QUOTE_TICK = 1000;
const DELAY_BEFORE_FIRST_QUOTING = 1000;
let refetchQuoteTimeout: NodeJS.Timeout | number | undefined;

export const CreateTransactionContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const refetchQuoteTimeoutRef = useRef(refetchQuoteTimeout);
  const { organizationId } = useParams() as { organizationId: string };
  const [createQuote, { isLoading: isQuoteInProgress, data: createdQuoteData }] =
    useCreateQuoteMutation({
      fixedCacheKey: 'createQuote',
    });
  const secondsToUpdateQuoteRef = useRef<number>(0);
  const [secondsCounter, setSecondsCounter] = useState<number>(0);
  const [sourceAmount, setSourceAmount] = useState('');
  const [sourceAssetType, setSourceAssetType] = useState<AssetType | undefined>();
  const [isSourceAmountValid, setIsSourceAmountValid] = useState(true);
  const [targetAmount, setTargetAmount] = useState('');
  const [targetAssetType, setTargetAssetType] = useState<AssetType | undefined>();
  const [isTargetAmountValid, setIsTargetAmountValid] = useState(true);
  const [sender, setSender] = useState<SelectFieldOption | undefined>();
  const [recipient, setRecipient] = useState<SelectFieldOption | undefined>();
  const [transactionPurpose, setTransactionPurpose] = React.useState<TransactionPurpose>();
  const [supportingDocumentFile, setSupportingDocumentFile] = React.useState<File>();
  const [reference, setReference] = React.useState<string>('');

  const [amount, setAmount] = useState('');
  const [amountType, setAmountType] = useState<'source' | 'target' | 'none'>('source');

  const { data: liquidityLimits, refetch: refetchGetLiquidityLimitsQuery } =
    useGetLiquidityLimitsQuery({
      organizationId,
    });

  const liquidityLimitStatus = useMemo(
    () => getLiquidityLimitStatus(liquidityLimits || [], targetAmount, targetAssetType),
    [liquidityLimits, targetAmount, targetAssetType],
  );

  const handleApiResponseError = error => {
    let errorMessage = `We're unable to generate a quote at this time. Please refresh the page and try again. If the issue persists, contact support.`;

    if (error.status === 403 && error.data.message.length > 0 && error.data.message[0].detail) {
      errorMessage = error.data.message[0].detail;
    }

    showError(errorMessage);
    clearTimeout(refetchQuoteTimeoutRef.current);
    setSecondsCounter(0);
  };

  const manageQuoteRefresh = useCallback(async () => {
    if (amountType === 'none') {
      setTargetAmount('');
      setSourceAmount('');
    }

    if (!isQuoteInProgress && secondsToUpdateQuoteRef.current <= 1) {
      const sourceAmountOrUndefined = amountType === 'source' ? toWei(amount, 'mwei') : undefined;
      const targetAmountOrUndefined = amountType === 'target' ? toWei(amount, 'mwei') : undefined;

      const result = await createQuote({
        organizationId,
        sourceAmount: sourceAmountOrUndefined,
        sourceAssetType: sourceAssetType?.id || '',
        sourceBankId: sender?.value,
        targetAmount: targetAmountOrUndefined,
        targetAssetType: targetAssetType?.id || '',
        targetBankId: recipient?.value,
      });

      refetchGetLiquidityLimitsQuery();

      if (!('error' in result)) {
        const { data } = result;
        if (amountType === 'source') {
          setTargetAmount(convertFromBaseUnit(data.attributes.target).toString());
        } else if (amountType === 'target') {
          setSourceAmount(convertFromBaseUnit(data.attributes.source).toString());
        } else {
          setSourceAmount('');
          setTargetAmount('');
        }

        const createdAt = Date.parse(data.attributes.createdAt.toString());
        const expiresAt = Date.parse(data.attributes.expiresAt.toString());
        // Includes a 5 second tolerance
        const timeToUpdateQuoteExpiration = (expiresAt - createdAt) / 1000 - 5;
        secondsToUpdateQuoteRef.current = timeToUpdateQuoteExpiration;
      } else {
        handleApiResponseError(result.error);
        return;
      }
    } else {
      secondsToUpdateQuoteRef.current -= 1;
    }
    setSecondsCounter(secondsToUpdateQuoteRef.current);
    refetchQuoteTimeoutRef.current = setTimeout(manageQuoteRefresh, REFETCH_QUOTE_TICK);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isQuoteInProgress,
    createQuote,
    sender?.value,
    recipient?.value,
    organizationId,
    amount,
    amountType,
  ]);

  const areTargetAndSourceAssetsValidForQuoting = useMemo(() => {
    if (!sourceAssetType?.id || !targetAssetType?.id) {
      return false;
    }
    const baseAssetType = amountType === 'source' ? sourceAssetType : targetAssetType;
    const pairedAssetType = amountType === 'source' ? targetAssetType : sourceAssetType;
    const weiValue = toWei(amount, baseAssetType.precision) || '0';
    const assetTypePairRule = getAssetTypePairRule(baseAssetType, pairedAssetType);
    const assetTypePairRuleError = getAssetPairRuleValidationError(weiValue, assetTypePairRule);
    const minQuoteValidationError = getMinAmountValidationError(weiValue, baseAssetType);
    const maxQuoteValidationError = getMaxQuoteValidationError(weiValue, baseAssetType);
    return !minQuoteValidationError && !assetTypePairRuleError && !maxQuoteValidationError;
  }, [sourceAssetType, targetAssetType, amount, amountType]);

  const canCreateQuote = useMemo(
    () =>
      areTargetAndSourceAssetsValidForQuoting &&
      amount !== '' &&
      amount !== '0' &&
      !amount.includes('-')[amount],
    [amount, areTargetAndSourceAssetsValidForQuoting],
  );

  useEffect(() => {
    let debouncedManageQuoteRefresh: NodeJS.Timeout | number | undefined;
    secondsToUpdateQuoteRef.current = 0;
    setSecondsCounter(secondsToUpdateQuoteRef.current);
    if (canCreateQuote) {
      debouncedManageQuoteRefresh = setTimeout(manageQuoteRefresh, DELAY_BEFORE_FIRST_QUOTING);
    }

    return () => {
      clearTimeout(debouncedManageQuoteRefresh);
      clearTimeout(refetchQuoteTimeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canCreateQuote, sourceAssetType, targetAssetType, amount]);

  const quote: Quote | undefined = useMemo(
    () =>
      createdQuoteData
        ? {
            attributes: {
              ...createdQuoteData.attributes,
              sourceBankId: sender?.value,
              targetBankId: recipient?.value,
            },
            id: createdQuoteData.id,
            type: createdQuoteData.type,
          }
        : undefined,
    [createdQuoteData, sender, recipient],
  );

  const quoteStatusLabel = useMemo(() => {
    if (isQuoteInProgress) {
      return 'Quote updating';
    }
    if (secondsCounter > 0) {
      return `Quote updates in ${secondsCounter}s`;
    }
    return '';
  }, [isQuoteInProgress, secondsCounter]);

  const labels = useMemo(() => {
    let senderLabel = '';
    let recipientLabel = '';
    let targetAssetTypeTooltip = '';

    if (sourceAssetType) {
      senderLabel =
        isFiat(sourceAssetType) && sender
          ? `${sender.label} - ${sender.sublabel}`
          : `My ${getUriIdAndChainId(sourceAssetType)} Balance`;
    }
    if (targetAssetType) {
      recipientLabel =
        isFiat(targetAssetType) && recipient
          ? `${recipient.label} - ${recipient.sublabel}`
          : `My ${getUriIdAndChainId(targetAssetType)} Balance`;
      targetAssetTypeTooltip = targetAssetType.reverseQuoting?.disabledMessage ?? '';
    }
    return { recipientLabel, senderLabel, targetAssetTypeTooltip };
  }, [recipient, sender, sourceAssetType, targetAssetType]);

  const isQuoteFormDisabled = useMemo(
    () =>
      !!(
        (sourceAssetType?.operatingHours &&
          !isCurrentTimeInRange(sourceAssetType.operatingHours)) ||
        (targetAssetType?.operatingHours && !isCurrentTimeInRange(targetAssetType.operatingHours))
      ),
    [sourceAssetType, targetAssetType],
  );

  const isReverseQuotingDisabled = useMemo(
    () => targetAssetType?.reverseQuoting?.isDisabled ?? false,
    [targetAssetType],
  );

  const contextValue = useMemo(
    (): CreateTransactionContextType => ({
      isQuoteFormDisabled,
      isQuoteInProgress,
      isReverseQuotingDisabled,
      isSourceAmountValid,
      isTargetAmountValid,
      liquidityLimitStatus,
      quote,
      quoteStatusLabel,
      recipient,
      recipientLabel: labels.recipientLabel,
      reference,
      sender,
      senderLabel: labels.senderLabel,
      setAmount,
      setAmountType,
      setIsSourceAmountValid,
      setIsTargetAmountValid,
      setRecipient,
      setReference,
      setSender,
      setSourceAmount,
      setSourceAssetType,
      setSupportingDocumentFile,
      setTargetAmount,
      setTargetAssetType,
      setTransactionPurpose,
      sourceAmount,
      sourceAssetType,
      supportingDocumentFile,
      targetAmount,
      targetAssetType,
      targetAssetTypeTooltip: labels.targetAssetTypeTooltip,
      transactionPurpose,
    }),
    [
      isQuoteInProgress,
      isReverseQuotingDisabled,
      labels.targetAssetTypeTooltip,
      labels.recipientLabel,
      labels.senderLabel,
      liquidityLimitStatus,
      quote,
      quoteStatusLabel,
      isQuoteFormDisabled,
      isSourceAmountValid,
      isTargetAmountValid,
      setIsSourceAmountValid,
      setIsTargetAmountValid,
      recipient,
      reference,
      setReference,
      sender,
      sourceAmount,
      sourceAssetType,
      supportingDocumentFile,
      targetAmount,
      targetAssetType,
      transactionPurpose,
    ],
  );

  return (
    <CreateTransactionContext.Provider value={contextValue}>
      {children}
    </CreateTransactionContext.Provider>
  );
};
