import React, { forwardRef, useMemo } from 'react';
import cx from 'classnames';

import FormErrorMessage from 'components/core/Form/FormErrorMessage/FormErrorMessage';
import generateUid from 'utils/generateUid';

import CheckboxFieldProps from './CheckboxField.types';
import styles from './CheckboxField.module.scss';

function CheckboxField<T extends CheckboxFieldProps>(
  {
    className,
    dataTestId = '',
    error = '',
    id,
    isChecked,
    isDisabled = false,
    isRequired = false,
    label = '',
    name,
    onChange,
    ...rest
  }: T,
  ref,
) {
  const fieldId = useMemo(() => {
    return id || generateUid();
  }, [id]);

  return (
    <div className={cx(styles.root, className)}>
      <input
        ref={ref}
        checked={isChecked}
        className={cx(styles.input, error && styles.hasError)}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={fieldId}
        name={name}
        onChange={onChange}
        required={isRequired}
        type='checkbox'
        {...rest}
      />
      <div className={styles.checkbox} />
      <div className={styles.contentWrapper}>
        <label className={styles.label} htmlFor={fieldId}>
          {label}
        </label>
        <FormErrorMessage
          className={styles.errorMessage}
          dataTestId={`${dataTestId}-error`}
          message={error}
        />
      </div>
    </div>
  );
}

export default forwardRef(CheckboxField);
