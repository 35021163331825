export const PAGE_SIZE_OPTIONS = [
  { label: '25', value: 25 },
  { label: '50', value: 50 },
  { label: '100', value: 100 },
  { label: '200', value: 200 },
];

export const PAGE_SIZE_OPTIONS_DEFAULT = PAGE_SIZE_OPTIONS[1];

export const ELLIPSIS = '...';
