import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  PAGE_SIZE_OPTIONS,
  PAGE_SIZE_OPTIONS_DEFAULT,
} from 'components/core/PaginationBar/PaginationBar.constants';
import { PaginationParams } from 'constants/queryParams';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';

const usePaginationQuery = (): {
  handleOnLimitSelect: (option: SelectFieldOption | null) => void;
  pageAfter: string | null;
  pageBefore: string | null;
  pageNumber: string | null;
  pageSize: SelectFieldOption;
  setPageSize: (pageSize: SelectFieldOption) => void;
} => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const queryParamPageAfter = params.get('pageAfter');
  const queryParamPageBefore = params.get('pageBefore');
  const queryParamPageNumber = params.get('pageNumber');
  const queryParamSize = params.get('pageSize');

  const [pageSize, setPageSize] = useState<SelectFieldOption>(
    PAGE_SIZE_OPTIONS.find(
      option => option.value === (queryParamSize ? parseInt(queryParamSize, 10) : null),
    ) || PAGE_SIZE_OPTIONS_DEFAULT,
  );

  const handleOnLimitSelect = useCallback(
    (option: SelectFieldOption | null) => {
      const newPageSize = option || PAGE_SIZE_OPTIONS_DEFAULT;
      setPageSize(newPageSize);
      navigate(`${window.location.pathname}?${PaginationParams.PageSize}=${newPageSize.value}`);
    },
    [navigate, setPageSize],
  );

  return {
    handleOnLimitSelect,
    pageAfter: queryParamPageAfter,
    pageBefore: queryParamPageBefore,
    pageNumber: queryParamPageNumber,
    pageSize,
    setPageSize,
  };
};

export default usePaginationQuery;
