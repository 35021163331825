import { OptionProps } from 'react-select';
import React, { FC } from 'react';
import cx from 'classnames';

import styles from './SelectFieldOption.module.scss';

import { SelectFieldOption as SelectFieldOptionType } from '../SelectField.types';

const SelectFieldOption: FC<OptionProps<SelectFieldOptionType>> = ({
  innerRef,
  innerProps,
  data,
  isDisabled,
  isFocused,
  isSelected,
}) => {
  const { label, sublabel, component } = data;
  return (
    <div
      ref={innerRef}
      className={cx(
        styles.root,
        isDisabled && styles.isDisabled,
        isFocused && styles.isFocused,
        isSelected && styles.isSelected,
      )}
      {...innerProps}
    >
      {component || label}
      {!component && sublabel && <div className={styles.sublabel}>{sublabel}</div>}
    </div>
  );
};

export default SelectFieldOption;
