import React, { FC, useMemo } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import {
  TransactionDepositInstructionsDefault,
  TransactionDepositInstructionsType,
} from 'store/api/api.types';
import Box from 'components/core/Box/Box';
import Button from 'components/core/Button/Button';
import DepositInstructions from 'components/dedicated/DepositInstructions/DepositInstructions';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

type USDDepositInstructionsProps = { assetType: AssetType };

const USDDepositInstructions: FC<USDDepositInstructionsProps> = ({
  assetType,
}: USDDepositInstructionsProps) => {
  const { fedwireInstructions, swiftInstructions } = useMemo<{
    fedwireInstructions: TransactionDepositInstructionsDefault;
    swiftInstructions: TransactionDepositInstructionsDefault;
  }>(() => {
    return (assetType.depositInstructions || []).reduce(
      (acc, instruction) => {
        if (instruction.type === TransactionDepositInstructionsType.International) {
          return {
            ...acc,
            swiftInstructions: instruction,
          };
        }

        return {
          ...acc,
          fedwireInstructions: instruction,
        };
      },
      {
        fedwireInstructions: {} as TransactionDepositInstructionsDefault,
        swiftInstructions: {} as TransactionDepositInstructionsDefault,
      },
    );
  }, [assetType]);

  return (
    <>
      <ModalContent>
        <Box display='flex' flexDirection='column' gap={6}>
          <Text marginBottom={3}>
            Send USD using the instructions below. Once processed by the bank, your balance will
            automatically update.
          </Text>
          <Box>
            <DepositInstructions
              depositInstructions={[fedwireInstructions, swiftInstructions]}
              shouldShowRequiredFieldsMessages={false}
              useUSDLocalTabNames
            />
          </Box>
        </Box>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button label='Close' onClick={() => modalService.closeCurrentModal()} variant='tertiary' />
      </ModalContent>
    </>
  );
};

export default USDDepositInstructions;
