import { DotProps } from 'components/core/Dot/Dot.types';
import { TransactionStatus } from 'store/api/api.types';

export const getAttributesByStatus = (
  status: TransactionStatus,
): { color: DotProps['color']; showDot?: boolean } => {
  switch (status) {
    case TransactionStatus.Created:
      return {
        color: 'yellow',
        showDot: true,
      };
    case TransactionStatus.AwaitingFunds:
      return {
        color: 'orange',
        showDot: true,
      };
    case TransactionStatus.AwaitingComplianceReview:
      return {
        color: 'violet',
        showDot: true,
      };
    case TransactionStatus.FundsReceived:
      return {
        color: 'yellow',
        showDot: true,
      };
    case TransactionStatus.InComplianceReview:
      return {
        color: 'yellow',
        showDot: true,
      };
    case TransactionStatus.ComplianceApproved:
      return {
        color: 'blue',
        showDot: true,
      };
    case TransactionStatus.ProcessingSettlement:
      return {
        color: 'blue',
        showDot: true,
      };
    case TransactionStatus.ProcessingPayment:
      return {
        color: 'blue',
        showDot: true,
      };
    case TransactionStatus.ProcessingWithdrawal:
      return {
        color: 'blue',
        showDot: true,
      };
    case TransactionStatus.SettlementProcessed:
      return {
        color: 'green',
      };
    case TransactionStatus.PaymentProcessed:
      return {
        color: 'green',
      };
    case TransactionStatus.WithdrawalProcessed:
      return {
        color: 'green',
      };
    case TransactionStatus.Completed:
      return {
        color: 'green',
      };
    case TransactionStatus.ComplianceRejected:
      return {
        color: 'red',
      };
    case TransactionStatus.Cancelled:
      return {
        color: 'gray',
      };
    default:
      return {
        color: 'blue',
        showDot: true,
      };
  }
};
