import React, { FC, memo } from 'react';

import { SelectFieldProps } from 'components/core/Form/SelectField/SelectField.types';
import SelectField from 'components/core/Form/SelectField/SelectField';

const SelectFieldComponent: FC<SelectFieldProps> = ({ id, value, ...props }) => (
  <SelectField
    key={id}
    isRequired
    placeholder={`Select ${props.label}`}
    value={props.options?.find(option => option.value === value)}
    {...props}
  />
);

export default memo(SelectFieldComponent);
