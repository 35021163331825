import { setUser } from '@sentry/react';
import React, { FC, ReactNode, useEffect, useMemo } from 'react';

import { useGetCurrentUserQuery } from 'store/api/rootApi';
import LoaderView from 'components/views/LoaderView/LoaderView';

import { CurrentUserContextType } from './CurrentUserContext.types';
import { isUserAllowedTo } from './CurrentUserContext.helpers';
import CurrentUserContext from './CurrentUserContext';

export const CurrentUserContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { isLoading, isUninitialized, data: currentUser } = useGetCurrentUserQuery();

  useEffect(() => {
    if (currentUser) {
      setUser({
        id: currentUser.id,
      });
    } else {
      setUser(null);
    }
  }, [currentUser]);

  const contextValue = useMemo(
    (): CurrentUserContextType => ({
      isUserAllowedTo: isUserAllowedTo(currentUser?.authorizations.combinedPolicies || []),
      user: currentUser,
    }),
    [currentUser],
  );

  return (
    <CurrentUserContext.Provider value={contextValue}>
      {isLoading || isUninitialized ? <LoaderView /> : children}
    </CurrentUserContext.Provider>
  );
};
