import { Navigate, generatePath, useParams } from 'react-router-dom';
import React, { ReactElement, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { NO_PERMISSION_MESSAGE } from 'constants/constants';
import { ORGANIZATION_ROUTES } from 'router/constants';
import { useCurrentUser } from 'context/CurrentUserContext';
import ApiKeysTable from 'components/dedicated/organization/ApiKeysTable/ApiKeysTable';
import Button from 'components/core/Button/Button';
import ContentSection from 'components/core/ContentSection/ContentSection';
import ModalCreateKey, {
  modalCreateKeyTitle,
} from 'components/dedicated/ModalCreateKey/ModalCreateKey';
import PageHeader from 'components/core/PageHeader/PageHeader';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

const ApiKeysView = (): ReactElement => {
  const { organizationId } = useParams() as { organizationId: string };
  const { isUserAllowedTo } = useCurrentUser();

  const canReadKeys = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.ReadKey),
    [organizationId, isUserAllowedTo],
  );

  const canCreateKey = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateKey),
    [organizationId, isUserAllowedTo],
  );

  if (!canReadKeys) {
    const redirectionPath = generatePath(ORGANIZATION_ROUTES.SEND_MONEY.absolute, {
      organizationId,
    });
    return <Navigate replace to={redirectionPath} />;
  }

  return (
    <ContentSection>
      <PageHeader title='API Keys'>
        <Tooltip isHidden={canCreateKey} label={NO_PERMISSION_MESSAGE}>
          <Button
            isDisabled={!canCreateKey}
            label='Create Key'
            onClick={() =>
              modalService.openModal(
                {
                  title: modalCreateKeyTitle,
                },
                <ModalCreateKey organizationId={organizationId} />,
              )
            }
            size='small'
            type='submit'
            variant='primary'
          />
        </Tooltip>
      </PageHeader>
      <ApiKeysTable organizationId={organizationId} />
    </ContentSection>
  );
};

export default ApiKeysView;
