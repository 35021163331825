import { ELLIPSIS } from 'components/core/PaginationBar/PaginationBar.constants';
import { PageItem } from 'components/core/PaginationBar/PaginationBar.types';

export const generateFloatingPages = (
  currentPage: number,
  leftBorder: number,
  rightBorder: number,
  adjacentPages: number = 2,
): number[] => {
  const floatingPages: number[] = [];
  const floatingPagesCount = adjacentPages * 2 + 1;
  const leftOffsetValue = leftBorder + adjacentPages;
  const rightOffsetValue = rightBorder - adjacentPages;

  if (currentPage < leftBorder || currentPage > rightBorder) {
    return [];
  }

  if (currentPage <= leftOffsetValue) {
    for (let i = 1; i <= floatingPagesCount; i += 1) {
      floatingPages.push(i + leftBorder);
    }
  } else if (currentPage > leftOffsetValue && currentPage < rightOffsetValue) {
    for (let i = currentPage - adjacentPages; i <= currentPage + adjacentPages; i += 1) {
      floatingPages.push(i);
    }
  } else if (currentPage >= rightOffsetValue) {
    for (let i = rightBorder - floatingPagesCount; i < rightBorder; i += 1) {
      floatingPages.push(i);
    }
  }

  return floatingPages.filter(page => page > leftBorder && page < rightBorder);
};

export const generatePageNumbers = (
  currentPage: number,
  totalPages: number,
  maxAdjacentPages: number = 2,
  maxVisiblePagesWithoutEllipsis: number = 9,
): PageItem[] => {
  const pages: PageItem[] = [];
  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));

  // Display all pages without any ellipsis:
  if (totalPages <= maxVisiblePagesWithoutEllipsis) {
    for (let i = 1; i <= totalPages; i += 1) {
      pages.push(i);
    }
    return pages;
  }

  // Generates an array of floating pages part displayed between ellipsises,
  // e.g. 1 ... [6, 7, 8, 9, 10] ... 41
  const floatingPages = generateFloatingPages(validCurrentPage, 1, totalPages, maxAdjacentPages);

  // always display the first page
  pages.push(1);

  // handle left side ellipsis
  if (floatingPages[0] > 2) {
    pages.push(ELLIPSIS);
  }

  pages.push(...floatingPages);

  // handle right side ellipsis
  if (floatingPages[floatingPages.length - 1] < totalPages - 1) {
    pages.push(ELLIPSIS);
  }

  // always display the last page
  pages.push(totalPages);

  return pages;
};
