// Simple UID generator
export default function generateUid(length = 8, prefix = '', useMixedCase = false): string {
  let hash = '';
  let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  if (useMixedCase) {
    characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    hash += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return prefix ? `${prefix}-${hash}` : hash;
}
