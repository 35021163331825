import { Link } from 'react-router-dom';
import React, { FC } from 'react';

import { CONTACT_US_URL, DEVELOPER_DOCS_URL } from 'constants/constants';
import { ROOT_ROUTES } from 'router/constants';
import { conduitLogo, conduitSign } from 'components/core/Svg/illustrations';
import { help, menu } from 'components/core/Svg/icons';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useMobileMenu } from 'context/MobileMenuContext';
import Button from 'components/core/Button/Button';
import Dropdown from 'components/core/Dropdown/Dropdown';
import MenuItem from 'components/core/MenuItem/MenuItem';
import OrganizationSwitcherDropdown from 'components/dedicated/organization/OrganizationSwitcherDropdown/OrganizationSwitcherDropdown';
import Svg from 'components/core/Svg/Svg';
import auth0Client from 'services/auth0Client';

import TopbarProps from './Topbar.types';
import styles from './Topbar.module.scss';

const Topbar: FC<TopbarProps> = ({
  showMobileMenuButton = false,
  showOrganizationSwitcher = true,
  showUserMenu = true,
}) => {
  const { setIsMenuOpen } = useMobileMenu();
  const { user } = useCurrentUser();

  return (
    <div className={styles.root}>
      <div className={styles.topBar}>
        {showMobileMenuButton && (
          <Button
            className={styles.mobileMenuButton}
            LeadingIcon={<Svg img={menu} size={1.8} />}
            onClick={() => {
              setIsMenuOpen(true);
            }}
            size='small'
            variant='icon'
          />
        )}
        <Link className={styles.headerLogo} to={ROOT_ROUTES.APP.absolute}>
          <Svg className={styles.logoFull} img={conduitLogo} size={[11, 2.5]} />
          <Svg className={styles.logoSign} img={conduitSign} size={[3, 3]} />
        </Link>
        <nav className={styles.navItems}>
          {showOrganizationSwitcher && (
            <OrganizationSwitcherDropdown className={styles.organizationDropdown} />
          )}
          <Dropdown
            className={styles.helpDropdown}
            LeadingIcon={<Svg img={help} size={1.8} />}
            showArrow
            variant='icon'
          >
            <MenuItem href={DEVELOPER_DOCS_URL} label='Developer Docs' target='_blank' />
            <MenuItem href={CONTACT_US_URL} label='Contact Us' target='_blank' />
          </Dropdown>
          {showUserMenu && (
            <Dropdown
              className={styles.userProfileDropdown}
              label={user?.email}
              menuWidth='wide'
              variant='dropdownTransparent'
            >
              <MenuItem
                label='Sign Out'
                onClick={() => {
                  auth0Client.logout({
                    logoutParams: {
                      returnTo: window.location.origin,
                    },
                  });
                }}
              />
            </Dropdown>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Topbar;
