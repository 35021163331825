import { getMandatoryBankDetailsFields } from './bankDetails';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';

export const areBankDetailsValid = (bankDetails: BankDetails): boolean =>
  Object.values(getMandatoryBankDetailsFields(bankDetails)).every(value => !!value);

export const defaultBankDetails: BankDetails = {
  accountNumber: '',
  accountType: '',
  bankName: '',
  bicSwiftCode: '',
  country: '',
  currency: '',
  nameOnBankAccount: '',
  routingCode: '',
};

export const getBankTitle = (
  bankDetails: Pick<BankDetails, 'bankName' | 'accountNumber'>,
): string => `${bankDetails.bankName} - ${bankDetails.accountNumber.slice(-4)}`;
