import { generatePath } from 'react-router-dom';

import { ActionName, CurrentUserContextType } from 'context/CurrentUserContext.types';
import { Organization } from 'store/api/api.types';

import { ORGANIZATION_ROUTES } from './constants';
import { Path } from './utils';

interface OrganizationMenuItem {
  label: string;
  path: Path;
  requiredPermissions?: ActionName;
}

const MENU_ITEMS: OrganizationMenuItem[] = [
  {
    label: 'Send Money',
    path: ORGANIZATION_ROUTES.SEND_MONEY,
    requiredPermissions: ActionName.CreateQuote,
  },
  {
    label: 'Transactions',
    path: ORGANIZATION_ROUTES.TRANSACTIONS,
    requiredPermissions: ActionName.ReadTransaction,
  },
  {
    label: 'Senders & Recipients',
    path: ORGANIZATION_ROUTES.SENDERS_RECIPIENTS,
    requiredPermissions: ActionName.ReadIdentity,
  },
  {
    label: 'API Keys',
    path: ORGANIZATION_ROUTES.API_KEYS,
    requiredPermissions: ActionName.ReadKey,
  },
  {
    label: 'Members',
    path: ORGANIZATION_ROUTES.MEMBERS,
    requiredPermissions: ActionName.ReadMember,
  },
];

export const getOrganizationMenuItemPath = (
  organizationId: Organization['id'],
  path: Path,
): Path => ({
  absolute: generatePath(path.absolute, {
    organizationId,
  }),
  relative: path.relative,
});

export const getOrganizationMenuItems = (
  organization: Organization,
  isUserAllowedTo: CurrentUserContextType['isUserAllowedTo'],
): OrganizationMenuItem[] => {
  return MENU_ITEMS.filter(menuItem => {
    if (!menuItem.requiredPermissions) {
      return true;
    }
    return isUserAllowedTo(organization.id, menuItem.requiredPermissions);
  }).map(item => ({
    label: item.label,
    path: getOrganizationMenuItemPath(organization.id, item.path),
  }));
};
