import colors from './_colorsExportToJS.module.scss';

export const {
  violetDarkest,
  violetDarker,
  violetDark,
  violetBase,
  violetLight,
  violetLighter,
  violetLightest,
  orangeBase,
  orangeLightest,
  navyDarkest,
  grayDarker,
  grayDark,
  grayBase,
  grayLight,
  grayLighter,
  grayLightest,
  white,
  blackWarm,
  charcoalDarker,
  charcoalBase,
  charcoalLight,
  yellowBase,
  yellowLighter,
  yellowLightest,
  greenBase,
  greenLight,
  greenLightest,
  redDarkest,
  redDark,
  redBase,
  redLighter,
  redLightest,
  blueBase,
  blueLightest,
} = colors;
