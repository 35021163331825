import React, { FC, useCallback, useMemo } from 'react';

import { FormBankDetailsTypes } from './FormBankDetails.types';
import styles from './FormBankDetails.module.scss';

import { BankDetailsComponentId } from '../config/bankDetails.types';
import { getBankDetailsFormConfig } from '../config/bankDetails';

const FormBankDetails: FC<FormBankDetailsTypes> = ({
  bankDetails,
  setBankDetailsField,
  isProcessing,
}) => {
  const formComponents = useMemo(() => {
    return getBankDetailsFormConfig(bankDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankDetails.country, bankDetails.currency]);

  const handleChange = useCallback(
    (id, value) => {
      setBankDetailsField(id, value);
    },
    [setBankDetailsField],
  );

  return (
    <div className={styles.wrapper}>
      <h4>Bank Details</h4>
      {
        // Show only country and currency fields until they are filled.
        formComponents
          .filter(
            component =>
              [BankDetailsComponentId.country, BankDetailsComponentId.currency].includes(
                component.id,
              ) || bankDetails.currency,
          )
          .map(({ Component, id, props }) => (
            <Component
              key={id}
              onChange={({ target: { value } }) => handleChange(id, value)}
              value={bankDetails[id]}
              {...props}
              isDisabled={props.isDisabled || isProcessing}
            />
          ))
      }
    </div>
  );
};

export default FormBankDetails;
