import { BankDetailsComponentId, BankDetailsComponents } from './bankDetails.types';
import { IdentityDetailsComponentId, IdentityDetailsComponents } from './identityDetails.types';

// While it's generally not a good practice to mutate the object,
// it's done here to avoid creating a new object and simplify
// the call, making the code more readable.
export const createSetIsVisibleHelper =
  (components: BankDetailsComponents | IdentityDetailsComponents) =>
  (
    componentId: BankDetailsComponentId | IdentityDetailsComponentId,
    isVisible: boolean = true,
  ): void => {
    // eslint-disable-next-line no-param-reassign
    components[componentId].isVisible = isVisible;
  };
