import React, { FC } from 'react';

import Box from 'components/core/Box/Box';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';

import { ExternalLinkProps } from './ExternalLink.types';

const openLink = (link: string) => window.open(link, '_blank', 'noopener,noreferrer');

const ExternalLink: FC<ExternalLinkProps> = ({
  children,
  link,
  iconName = 'linkTab',
  iconSize = 1.1,
  variant = 'bodyLinkStandard',
  ...props
}) => (
  <Text display='inline-flex' onClick={() => openLink(link)} variant={variant} {...props}>
    {children}{' '}
    <Box display='inline-flex' marginLeft={1}>
      <Icon iconName={iconName} size={iconSize} />
    </Box>
  </Text>
);

export default ExternalLink;
