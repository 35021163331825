import { Context, useContext } from 'react';

export function getContextFromProvider<T>(context: Context<T | undefined>) {
  return function useProvidedContext(): T {
    const contextValue = useContext(context);
    if (contextValue === undefined) {
      throw new Error('useProvidedContext must be used within a Provider');
    }
    return contextValue as T;
  };
}
