import React from 'react';

import { SvgProps } from 'components/core/Svg/Svg.types';
// eslint-disable-next-line no-restricted-syntax
import * as icons from 'components/core/Svg/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';

import { IconProps } from './Icon.types';

export const Icon: React.FC<IconProps> = ({
  iconName,
  alt,
  color,
  onClick,
  size,
  sizeUnit,
  title,
  ...props
}) => {
  const svgProps: SvgProps = {
    alt,
    color,
    img: icons[iconName],
    onClick,
    size,
    sizeUnit,
    title,
  };
  return (
    <Box display='inline-flex' {...props}>
      <Svg {...svgProps} />
    </Box>
  );
};
export default Icon;
