import { CSSTransition } from 'react-transition-group';
import { Link, useParams } from 'react-router-dom';
import React, { FC, useMemo, useRef } from 'react';

import { DEFAULT_TRANSITION_TIME } from 'constants/constants';
import { Organization } from 'store/api/api.types';
import { ROOT_ROUTES } from 'router/constants';
import { conduitLogo } from 'components/core/Svg/illustrations';
import { getOrganizationMenuItems } from 'router/menuItems';
import { useCurrentUser } from 'context/CurrentUserContext';
import { useGetOrganizationQuery } from 'store/api/rootApi';
import { useMobileMenu } from 'context/MobileMenuContext';
import Backdrop from 'components/core/Backdrop/Backdrop';
import MenuItem from 'components/core/MenuItem/MenuItem';
import Svg from 'components/core/Svg/Svg';

import styles from './MobileMenu.module.scss';

const MobileMenu: FC = () => {
  const { organizationId } = useParams() as { organizationId: Organization['id'] };
  const { data: organization } = useGetOrganizationQuery({ organizationId });
  const { isMenuOpen, setIsMenuOpen } = useMobileMenu();
  const { isUserAllowedTo } = useCurrentUser();
  const nodeRef = useRef(null);
  const menuItems = useMemo(
    () => (organization ? getOrganizationMenuItems(organization, isUserAllowedTo) : []),
    [organization, isUserAllowedTo],
  );

  return (
    <div className={styles.root}>
      <Backdrop
        isVisible={isMenuOpen}
        onBackgroundClick={() => {
          setIsMenuOpen(false);
        }}
      />
      <CSSTransition
        classNames={{
          enter: styles['menuContainer--enter'],
          enterActive: styles['menuContainer--enterActive'],
          exit: styles['menuContainer--exit'],
          exitActive: styles['menuContainer--exitActive'],
        }}
        in={isMenuOpen}
        nodeRef={nodeRef}
        timeout={DEFAULT_TRANSITION_TIME}
        unmountOnExit
      >
        <div ref={nodeRef} className={styles.menuContainer} data-testid='MobileMenu'>
          <div className={styles.logoWrapper}>
            <Link to={ROOT_ROUTES.APP.absolute}>
              <Svg className={styles.logoFull} img={conduitLogo} size={[11, 2.5]} />
            </Link>
          </div>
          <nav className={styles.menu}>
            {menuItems.map((item, index) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                isNavlink
                label={item.label}
                to={item.path.absolute}
                variant='mobileMenu'
              />
            ))}
          </nav>
        </div>
      </CSSTransition>
    </div>
  );
};

export default MobileMenu;
