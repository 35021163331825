import React, { FC, useMemo } from 'react';
import cx from 'classnames';

import { getTransactionActivityStepsProps } from 'components/dedicated/TransactionActivity/TransactionActivity.utils';
import { useGetTransactionEventsQuery } from 'store/api/platformApi';
import Box from 'components/core/Box/Box';
import Spinner from 'components/core/Spinner/Spinner';
import TransactionActivityStep from 'components/dedicated/TransactionActivityStep/TransactionActivityStep';

import { TransactionActivityProps } from './TransactionActivity.types';
import styles from './TransactionActivity.module.scss';

const TransactionActivity: FC<TransactionActivityProps> = ({
  className,
  organizationId,
  transactionSummary,
  ...props
}) => {
  const { data: transactionEvents, isLoading } = useGetTransactionEventsQuery({
    organizationId,
    transactionId: transactionSummary.id,
  });

  const transactionActivityStepsProps = useMemo(
    () =>
      getTransactionActivityStepsProps(
        organizationId,
        transactionSummary,
        transactionEvents?.data || [],
      ),
    [organizationId, transactionEvents, transactionSummary],
  );

  if (isLoading) {
    return <Spinner flexGrow={1} size='large' />;
  }

  return (
    <Box className={cx(styles.root, className)} {...props}>
      {transactionActivityStepsProps.map((stepProps, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <TransactionActivityStep key={`${index}-${stepProps.date || 'planned'}`} {...stepProps} />
      ))}
    </Box>
  );
};

export default TransactionActivity;
