import React, { FC, useState } from 'react';

import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';
import downloadFile from 'utils/downloadFile';

import { FileDownloadLinkProps } from './FileDownloadLink.types';

const FileDownloadLink: FC<FileDownloadLinkProps> = ({ label, fileName, url, ...props }) => {
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDownload = async () => {
    if (!isProcessing) {
      setIsProcessing(true);
      await downloadFile(url, fileName);
      setIsProcessing(false);
    }
  };

  return (
    <Text display='inline-flex' onClick={handleDownload} {...props}>
      {label || fileName}{' '}
      {isProcessing && <Spinner display='inline-block' marginLeft={2} size='xsmall' />}
    </Text>
  );
};

export default FileDownloadLink;
