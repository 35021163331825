import { fromWei } from 'web3-utils';
import { toNumber } from 'lodash';

import { AssetType } from 'constants/assetTypes.types';
import { CHAINS } from 'constants/assetTypes';
import { Chain, QuoteAssetEntry, TransactionAttributes } from 'store/api/api.types';

export const getUriId = (uri?: AssetType | string): string => {
  return (typeof uri === 'string' ? uri?.split(':').pop() : uri?.id.split(':').pop()) || '';
};

export const getUriChainId = (uri?: AssetType | string): string => {
  if (!uri) {
    return '';
  }
  const uriParts = (typeof uri === 'string' ? uri : uri.id).split(':');
  const chain = uriParts.length > 1 && uriParts[1] !== 'fiat' ? uriParts[1] : '';
  return chain.toUpperCase();
};

export const getUriChain = (uri?: AssetType | string): Chain | undefined => {
  return CHAINS.find(chain => chain.id === getUriChainId(uri));
};

export const getUriChainName = (uri?: AssetType | string): string => {
  return getUriChain(uri)?.name || '';
};

export const getUriIdAndChainId = (uri?: AssetType | string): string => {
  const chain = getUriChainId(uri);
  return getUriId(uri) + (chain !== '' ? ` (${chain})` : '');
};

// Prints as e.g. "USDC (ETH) - USD Coin" or "COP - Colombian Peso"
export const getDetailedAssetName = (assetType: AssetType): string =>
  `${getUriIdAndChainId(assetType)} - ${assetType.name}`;

export const isFiat = (assetType?: AssetType | string | null): boolean => {
  if (!assetType) {
    return false;
  }
  return typeof assetType === 'string'
    ? (assetType as string).includes(':fiat:')
    : ((assetType as AssetType).id || '').includes(':fiat:');
};

/**
 * This function is meant to be used for converting amounts for inputs, if you need to format values for rendering purposes, please use the formatAmountMethod
 */
export const convertFromBaseUnit = (
  assetType?: AssetType | QuoteAssetEntry | TransactionAttributes,
  decimals = -1,
): string => {
  if (assetType) {
    let amount = '0';
    const type = 'assetType' in assetType ? assetType.assetType : assetType.id;

    if ('balance' in assetType) {
      amount = assetType.balance || '0';
    } else if ('amount' in assetType) {
      amount = assetType.amount;
    } else {
      return '0';
    }

    if ('precision' in assetType) {
      amount = fromWei(amount, assetType.precision);
    } else {
      amount = fromWei(amount, 'Mwei');
    }

    if (decimals > -1 || isFiat(type)) {
      amount = parseFloat(toNumber(amount).toFixed(decimals > -1 ? decimals : 2)).toString();
    }

    return amount;
  }

  return '0';
};

/**
 * this function is meant to be used just for rendering purposes, if you need to convert an amount to be used as input value please use the convertFromBaseUnit method instead
 */
export const formatAmount = (
  assetType?: AssetType | QuoteAssetEntry | TransactionAttributes,
  decimals = -1,
): string => {
  if (assetType) {
    const convertedAmount = convertFromBaseUnit(assetType, decimals);
    // adds the comma to the amount, if the decimal is higher than 3 digits, make sure that it satisfies the decimals paramenter, otherwise it leaves empty.
    return toNumber(convertedAmount).toLocaleString('en-US', {
      minimumFractionDigits: decimals > 3 ? decimals : undefined,
    });
  }

  return '0';
};

export const formatAssetType = (assetType?: AssetType | QuoteAssetEntry, decimals = -1): string => {
  if (assetType) {
    const type = 'assetType' in assetType ? assetType.assetType : assetType.id;
    return `${formatAmount(assetType, decimals)} ${getUriIdAndChainId(type)}`;
  }
  return '';
};

export const toTitleCase = (word: string): string => {
  return word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase();
};

// Please note: this function does not round the decimals,
// it just truncates them.
export const formatWeiToHumanReadable = (
  wei: bigint,
  decimals: 0 | 1 | 2 | 3 = 0,
  hideZeroesInDecimals = true,
): string => {
  const units = ['M', 'B', 'T'];
  let unitIndex = 0;
  const mwei = wei / BigInt(1000000);
  let value = mwei;
  let decimalsPart = '';
  const minimalDivider = BigInt(1000000);

  if (value < minimalDivider) {
    return value.toString();
  }

  value /= minimalDivider;
  while (value >= BigInt(1000) && unitIndex < units.length - 1) {
    value /= BigInt(1000);
    unitIndex += 1;
  }

  if (decimals > 0) {
    const valueDivider =
      (BigInt(1000) ** BigInt(unitIndex) * minimalDivider) / BigInt(10) ** BigInt(decimals);
    const dividedValue = mwei / valueDivider;
    const decimalsString = dividedValue.toString().slice(-decimals);
    if (!hideZeroesInDecimals || !decimalsString.split('').every(char => char === '0')) {
      decimalsPart = `.${decimalsString}`;
    }
  }

  return `${value}${decimalsPart}${units[unitIndex]}`;
};
