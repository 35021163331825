import 'react-hint/css/index.css';
import React, { ReactElement } from 'react';
import ReactHintFactory from 'react-hint';

import styles from './TooltipContainer.module.scss';

const ReactHint = ReactHintFactory(React);

export const TooltipContainer = (): ReactElement => (
  <ReactHint autoPosition className={styles.root} events />
);
