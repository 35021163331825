import React, { FC, ReactNode, useEffect, useState } from 'react';

import Backdrop from 'components/core/Backdrop/Backdrop';
import Modal from 'components/core/Modal/Modal';
import ModalProps from 'components/core/Modal/Modal.types';

import { ModalServiceWrapperProps, SetModalPropsProps } from './ModalServiceWrapper.types';

const ModalServiceWrapper: FC<ModalServiceWrapperProps> = ({ modalService }) => {
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<ModalProps['isOpen']>(false);
  const [modalOnClosed, setModalOnClosed] = useState<ModalProps['onClosed']>();
  const [modalProps, setModalProps] = useState<SetModalPropsProps>({});
  const [ModalContent, setModalContent] = useState<ReactNode>(null);

  useEffect(() => {
    modalService.bindServiceWrapperMethods({
      setIsBackdropVisible,
      setIsModalOpen,
      setModalContent,
      setModalOnClosed,
      setModalProps,
    });
  }, [modalService]);

  const isSlideOutType = modalProps.type === 'slideout';

  const handleBackdropClick = () => {
    if (isSlideOutType) {
      modalService.closeCurrentModal();
    }
  };

  return (
    <Backdrop
      isVisible={isBackdropVisible}
      justifyContent={isSlideOutType ? 'flex-end' : 'center'}
      onBackgroundClick={handleBackdropClick}
    >
      <Modal isOpen={isModalOpen} onClosed={modalOnClosed} {...modalProps}>
        {ModalContent}
      </Modal>
    </Backdrop>
  );
};

export default ModalServiceWrapper;
